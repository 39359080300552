export const nonComputeServices = [
  'RDS',
  'ElastiCache',
  'MemoryDB',
  'OpenSearch',
  'Redshift'
];
export const nonComputeRIServices = [
  'RDS Reserved Instances',
  'ElastiCache Reserved Nodes',
  'MemoryDB Reserved Nodes',
  'OpenSearch Reserved Instances',
  'Redshift Reserved Nodes'
];

export const nonComputeSavingsShareServices = [
  'ProsperOps RDS Savings Share',
  'ProsperOps ElastiCache Savings Share',
  'ProsperOps MemoryDB Savings Share',
  'ProsperOps OpenSearch Savings Share',
  'ProsperOps Redshift Savings Share'
];

export const allNonComputeServices = nonComputeServices.concat(nonComputeRIServices).concat(nonComputeSavingsShareServices);
