<script setup lang="ts">
import type { GoogleCloudOfferingCode } from '@gcp/utilities/google_cloud_helpers';
import type { NumericMetricPanelProps } from '@shared/design/panels/NumericMetricPanel.vue';

import { useHead } from '@unhead/vue';
import moment from 'moment';
import { onBeforeMount, onMounted, computed, ref } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';
import { requestCloudSQLSavingsAnalysis } from '@gcp/services/billingAccountService';
import GoogleCloudHelpers from '@gcp/utilities/google_cloud_helpers';
import storage from '@shared/lib/local_storage';
import { PermissionLevel, useAuthStore } from '@shared/state/auth.store';
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import CalendlyWidget from '@console/components/widgets/CalendlyWidget.vue';
import Layout from '@console/Layout.vue';
import LoadingButton from '@shared/components/LoadingButton.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import EffectiveSavingsRateIcon from '@shared/design/icons/EffectiveSavingsRateIcon.vue';
import SavingsIcon from '@shared/design/icons/SavingsIcon.vue';
import SearchIcon from '@shared/design/icons/SearchIcon.vue';
import UsageIcon from '@shared/design/icons/UsageIcon.vue';
import WasteIcon from '@shared/design/icons/WasteIcon.vue';
import InfoTooltip from '@shared/design/InfoTooltip.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import NumericMetricPanel from '@shared/design/panels/NumericMetricPanel.vue';
import Panel from '@shared/design/panels/Panel.vue';
import StatusPill from '@shared/design/StatusPill.vue';
import Tooltip from '@shared/design/Tooltip.vue';

const props = defineProps<{
  service: GoogleCloudOfferingCode;
}>();
const loading = ref(true);

const serviceDisplayName = computed(() => GoogleCloudHelpers.getDisplayName(props.service));

useHead({
  title: serviceDisplayName.value,
});

const vuexStore = useVuexStore();
const authStore = useAuthStore();

const isDemo = computed(() => vuexStore.getters['customer/isDemo']);
const selectedCompany = computed(() => vuexStore.getters['customer/selectedCompany']);

const selectedBillingAccountId = computed(() => vuexStore.getters['gcp/selectedBillingAccountId']);
const isAtLeastEditor = computed(() => authStore.isAtLeastRole(selectedCompany.value.id, PermissionLevel.Editor));

onMounted(async () => {
  await vuexStore.dispatch('gcp/loadServiceUsage', { service: props.service });
  loading.value = false;
});

const serviceUsage = computed(() => vuexStore.getters['gcp/serviceUsage']);

const usageEnd = computed(() => dateFormat(dateUtc(serviceUsage.value?.usage_period_end), 'MMMM DD, YYYY'));
const usageDays = computed(() => serviceUsage.value?.usage_days || 0);
const usage = computed(() => serviceUsage.value?.[`${props.service}_normalized_usage`] || 0);
const savings = computed(() => serviceUsage.value?.[`${props.service}_normalized_gross_savings`] || 0);
const esr = computed(() => serviceUsage.value?.[`${props.service}_normalized_effective_savings_rate`] || 0);
const waste = computed(() => serviceUsage.value?.[`${props.service}_normalized_waste`] || 0);

const cards = computed<Array<NumericMetricPanelProps>>(() => {
  const hasUsage = usage.value > 0;
  return [
    {
      name: 'Usage',
      value: usage.value,
      color: 'dark',
      icon: UsageIcon,
      numberStyle: 'currency',
      decimals: 0,
      zeroValue: '-',
    },
    {
      name: 'Savings',
      value: savings.value,
      color: 'success',
      valueColor: savings.value >= 0 ? 'success' : 'danger',
      icon: SavingsIcon,
      numberStyle: 'currency',
      decimals: 0,
      zeroValue: hasUsage ? '$0' : '-',
    },
    {
      name: 'Effective Savings Rate',
      value: esr.value / 100,
      color: 'primary',
      valueColor: savings.value >= 0 ? 'primary' : 'danger',
      icon: EffectiveSavingsRateIcon,
      numberStyle: 'percent',
      decimals: 1,
      zeroValue: hasUsage && savings.value === 0 ? '0%' : '-',
    },
    {
      name: 'Waste',
      value: waste.value,
      color: 'danger',
      icon: WasteIcon,
      numberStyle: 'currency',
      decimals: 0,
      zeroValue: hasUsage && savings.value === 0 ? '$0' : '-',
      description:
        'The amount of unutilized commitment dollars (this is being paid to Google Cloud but does not generate a discount).',
    },
  ];
});

const routingFormId = computed(() => 'cm2c-pgk-rtn');

type PageState = 'initial' | 'requested' | 'scheduled' | 'previously_scheduled';
type StoredPageState = {
  state: PageState;
  timestamp: number;
};

const pageState = ref<PageState>('initial');
const requestClicked = ref(false);
const isCalendlyLoaded = ref(false);

const storageKey = 'google_cloud_non_compute_savings_analysis_state';
onBeforeMount(() => {
  const stored = storage.get<StoredPageState>(storageKey);
  if (!stored) return;

  const expiration = moment(stored.timestamp).add(stored.state === 'requested' ? 2 : 14, 'day');
  if (expiration.isAfter(moment())) {
    pageState.value = stored.state;
    if (stored.state === 'scheduled') {
      pageState.value = 'previously_scheduled';
    }
  }
});

const updateState = (state: PageState) => {
  pageState.value = state;
  storage.set(storageKey, {
    state,
    timestamp: Date.now(),
  });
};

const onCalendlyLoaded = () => {
  isCalendlyLoaded.value = true;
  if (requestClicked.value) {
    updateState('requested');
  }
};

const onShowCalendar = async () => {
  requestClicked.value = true;
  await requestCloudSQLSavingsAnalysis(selectedBillingAccountId.value);
  if (isCalendlyLoaded.value) {
    updateState('requested');
  }
};

const onEventScheduled = () => {
  updateState('scheduled');
};
</script>

<template>
  <Layout :loading="loading" no-background-donuts>
    <template #default>
      <div class="pageRoot">
        <div class="pageContainer">
          <PageHeader wrap-utility>
            <h1>{{ serviceDisplayName }}</h1>

            <template v-slot:utility>
              <StatusPill variant="info-secondary">
                Autonomous Discount Management: <span class="font-weight-normal">Not Active</span>
              </StatusPill>
            </template>
          </PageHeader>
          <div>
            <p v-if="usageDays > 0">
              <span>For the {{ usageDays }} days ending {{ usageEnd }} without ProsperOps</span>
              <template v-if="usageDays < 30">
                <InfoTooltip>
                  <div style="max-width: 180px">30 days of data will be shown once available from Google Cloud.</div>
                </InfoTooltip>
              </template>
            </p>
            <p v-else>No usage in the last 30 days</p>
            <div class="row sectional">
              <div v-for="c in cards" :key="c.name" class="col-xs-12 col-sm-6 col-md-12 col-lg-6 col-xxl-3">
                <NumericMetricPanel v-bind="c" class="metricCard" />
              </div>
            </div>

            <div v-if="pageState === 'initial'" class="row sectional">
              <div class="col">
                <Panel class="requestPanel">
                  <div class="requestContent">
                    <div v-if="usage === 0" class="m-4">
                      <h4 class="text-center">
                        Nothing to see here. Check back later if you start using {{ serviceDisplayName }}.
                      </h4>
                    </div>
                    <template v-else>
                      <SearchIcon class="mb-3" />
                      <h4 class="text-center mx-2">
                        Learn how ProsperOps can optimize your spend with a free {{ serviceDisplayName }} Savings
                        Analysis
                      </h4>

                      <div class="interactions mt-3">
                        <Tooltip>
                          <LoadingButton
                            id="request-savings-analysis-button"
                            :loading="requestClicked"
                            :disabled="isDemo || !isAtLeastEditor"
                            @click="onShowCalendar"
                          >
                            Request Savings Analysis
                          </LoadingButton>
                          <template v-if="!isAtLeastEditor" #tooltip>
                            Requesting a Savings Analysis requires <strong>Owner</strong> or
                            <strong>Editor</strong> role permissions for this Google Cloud Billing Account. Please
                            contact your Administrator to change your role or have another user with sufficient
                            permissions make the request.
                          </template>
                        </Tooltip>
                      </div>
                    </template>
                  </div>
                </Panel>
              </div>
            </div>
          </div>
          <BoxMessage v-if="pageState === 'requested'" type="info" class="pt-3">
            <template v-slot:icon>
              <BaseIcon name="check" class="icon" />
            </template>
            <p class="mb-0">
              <strong>Your Cloud SQL savings analysis is now being generated!</strong> An expert from our team
              personally reviews them with you. That typically takes less than an hour, after which you receive copies.
              Let's go ahead and get that scheduled.
            </p>
          </BoxMessage>
          <BoxMessage v-if="pageState === 'scheduled'" type="success" class="pt-3">
            <template v-slot:icon>
              <BaseIcon name="calendar-check" variant="far" class="icon" />
            </template>
            <p class="mb-0">
              Your Cloud SQL savings analysis has been generated and a time has been scheduled to review them with one
              of our experts.
              <strong>Nice work!</strong>
            </p>
          </BoxMessage>
          <BoxMessage v-if="pageState === 'previously_scheduled'" type="success" class="pt-3">
            <template v-slot:icon>
              <BaseIcon name="calendar-check" variant="far" class="icon" />
            </template>
            <p class="mb-0">
              Your Cloud SQL savings analysis has been generated and a time has been scheduled to review them with one
              of our experts.
              <strong>Check the meeting invite for details or to make changes.</strong>
            </p>
          </BoxMessage>
          <CalendlyWidget
            class="calendly"
            :routing-form-id="routingFormId"
            load-silently
            :hide="pageState === 'initial' || pageState === 'previously_scheduled' || !isCalendlyLoaded"
            @event-scheduled="onEventScheduled"
            @loaded="onCalendlyLoaded"
          />
          <div class="blurredBackgroundContainer">
            <img src="@console/assets/images/console_blurred_background.svg" />
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import '@shared/scss/bootstrap.scss';

.pageRoot {
  --page-padding-y: 16px;
  --blurred-top: 700px;
  --blurred-margin-x: 20px;

  @include media-breakpoint-up(sm) {
    --blurred-top: 450px;
  }

  @include media-breakpoint-up(md) {
    --page-padding-y: 32px;
    --blurred-margin-x: 12px;
    --blurred-top: 750px;
  }

  @include media-breakpoint-up(lg) {
    --blurred-margin-x: 20px;
    --blurred-top: 430px;
  }

  @include media-breakpoint-up(xl) {
    --blurred-top: 380px;
    --blurred-margin-x: 32px;
  }

  @include media-breakpoint-up(xxl) {
    --blurred-top: 220px;
    --blurred-margin-x: 38px;
  }

  display: flex;
  flex-direction: column;
  height: 100%;

  .pageContainer {
    position: relative;
    padding-bottom: var(--page-padding-y);
  }

  // To make sure that the blurred background doesn't increase the page size we hide the overflow
  // and use negative margins so that it consumes all remaining space up to the bottom edge of the page
  .blurredBackgroundContainer {
    position: absolute;
    top: var(--blurred-top);
    z-index: -1;
    width: calc(100% + 2 * var(--blurred-margin-x));
    height: calc(100% + var(--page-padding-y) - var(--blurred-top));
    min-height: calc(100vh - var(--blurred-top) - 94px);
    margin-right: calc(-1 * var(--blurred-margin-x));
    margin-bottom: calc(var(--blurred-top) - var(--page-padding-y));
    margin-left: calc(-1 * var(--blurred-margin-x));
    overflow-y: hidden;

    img {
      position: absolute;
      width: 100%;
    }
  }

  .requestContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.75rem auto;
  }

  .metricCard {
    :deep(.metricValue) {
      font-size: 2.25rem;
    }
  }

  .calendlyDescription {
    max-width: 675px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .interactions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 767px) {
    .calendly {
      margin-top: 2rem;
      overflow: hidden;
      border-radius: 0.2rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1072px) {
    .calendly {
      margin-top: 2rem;
      overflow: hidden;
      border-radius: 0.2rem;
    }
  }
}
</style>
