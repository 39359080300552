<script lang="ts" setup>
import type { GetUserResponse } from '@shared/services/customer.models';

import { computed } from 'vue';

import Dropdown from '@shared/design/dropdowns/Dropdown.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

const props = defineProps<{
  user: GetUserResponse;
  canRemove: boolean;
}>();

const isPendingEmailVerification = computed(() => props.user.user_status === 'PendingEmailValidation');
</script>

<template>
  <Dropdown variant="outline-info" toggle-class="rounded-sm" dropdown-placement="bottom-end" dropdown-class="py-0">
    <template #toggleContent> Actions </template>

    <DropdownItem v-if="isPendingEmailVerification" @click="$emit('resend-verification', user)">
      <div class="pt-1 pb-1">
        <BaseIcon name="envelope" class="mr-1 text-secondary menuIcon" />
        Resend Verification Email
      </div>
    </DropdownItem>
    <DropdownItem @click="$emit('update-role', user)">
      <div class="pt-1 pb-1">
        <BaseIcon name="user-edit" class="mr-1 text-secondary menuIcon" />
        Update Role
      </div>
    </DropdownItem>
    <DropdownItem v-if="canRemove" @click="$emit('remove-user', user)">
      <div class="pt-1 pb-1">
        <BaseIcon name="user-minus" class="mr-1 text-secondary menuIcon" />
        Remove User
      </div>
    </DropdownItem>
  </Dropdown>
</template>

<style lang="scss" scoped>
.menuIcon {
  width: 20px;
  margin-left: -10px;
}
</style>
