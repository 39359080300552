<script setup lang="ts">
import { ref } from 'vue';

import awsService, { EditableAwsOrgSettings } from '@aws/services/awsService';
import { useVuexStore } from '@console/state/vuex/store';

import StepOne from './Wizard/StepOne.vue';
import StepTwo from './Wizard/StepTwo.vue';
import OnboardingProgress from '@console/components/onboarding/OnboardingProgress.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import Modal from '@shared/design/modals/Modal.vue';

const vuexStore = useVuexStore();

const props = defineProps<{
  showModal: boolean;
  s3BucketName?: string;
}>();

type OnboardingStep = 'start' | 'end';

const isLoading = ref(false);
const validationError = ref('');
const s3EditValue = ref(props.s3BucketName);
const currentStep = ref<OnboardingStep>('start');
const progressTrackerSteps = ref([
  {
    text: 'S3 Bucket',
    key: 'start',
    number: 1,
    isActive: true,
  },
  {
    text: 'IAM Policy',
    key: 'end',
    number: 2,
  },
]);

const emits = defineEmits<{ (e: 'onCloseModal', shouldRefresh: boolean): void }>();

async function goToIamPermissions() {
  if (s3EditValue.value && props.s3BucketName !== s3EditValue.value) {
    isLoading.value = true;

    await awsService.setSetting(
      vuexStore.getters['aws/selectedOrganizationId'],
      EditableAwsOrgSettings.CustomerDataExportS3BucketName,
      s3EditValue.value
    );

    await vuexStore.dispatch('aws/initializeAwsOrganizationSettings');

    isLoading.value = false;
  }

  progressTrackerSteps.value[1].isActive = true;
  currentStep.value = 'end';
}

function back() {
  progressTrackerSteps.value[1].isActive = false;
  currentStep.value = 'start';
  validationError.value = '';
}

async function validateAndEnable() {
  isLoading.value = true;

  const response = await awsService.enableCustomerDataExport(vuexStore.getters['aws/selectedOrganizationId']);
  if (response && response.iam_validation_error_message) {
    isLoading.value = false;
    validationError.value = response.iam_validation_error_message;
    return;
  }

  await vuexStore.dispatch('aws/initializeAwsOrganizationSettings');
  emits('onCloseModal', true);

  isLoading.value = false;
}
</script>

<template>
  <Modal
    :model-value="showModal"
    size="lg"
    title="Configure Data Export"
    @update:model-value="
      val => {
        if (!val) {
          emits('onCloseModal', false);
        }
      }
    "
  >
    <OnboardingProgress :steps="progressTrackerSteps" class="pb-3" />
    <BoxMessage v-if="validationError" type="error" border-variant="outlined" class="mt-1">
      <strong>Hmm, we weren't able to validate access.</strong> {{ validationError }}
    </BoxMessage>
    <div class="pt-3">
      <StepOne
        v-if="currentStep === 'start'"
        :s3-bucket-name="props.s3BucketName"
        @update:s3-bucket-name="val => (s3EditValue = val)"
      />
      <StepTwo v-if="currentStep === 'end'" />
    </div>
    <div class="d-flex justify-content-end mt-5">
      <button
        v-if="currentStep === 'start'"
        :disabled="!s3EditValue || isLoading"
        class="btn btn-primary rounded-sm"
        @click="goToIamPermissions"
      >
        Next
      </button>

      <button
        v-if="currentStep === 'end'"
        class="btn btn-outline-info rounded-sm mr-2"
        :disabled="isLoading"
        @click="back"
      >
        Back
      </button>
      <button
        v-if="currentStep === 'end'"
        class="btn btn-primary rounded-sm"
        :disabled="isLoading"
        @click="validateAndEnable"
      >
        Validate & Enable
      </button>
    </div>
  </Modal>
</template>
