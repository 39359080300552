<script lang="ts" setup>
import Dropdown from '@shared/design/dropdowns/Dropdown.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

export type ReallocationMethodType = 'shared' | 'earned';

const model = defineModel<ReallocationMethodType>({ required: true });
const props = defineProps<{
  disabled?: boolean;
}>();

const labels: Record<ReallocationMethodType, string> = {
  shared: 'Shared Benefit',
  earned: 'Earned Benefit',
};
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <Dropdown
    variant="transparent"
    toggle-class="rounded-0"
    dropdown-class="rounded-0"
    dropdown-placement="bottom-start"
    :disabled="props.disabled"
  >
    <template #toggleContent>
      {{ labels[model] }}
    </template>
    <DropdownItem @click="model = 'shared'"> {{ labels['shared'] }} </DropdownItem>
    <DropdownItem disabled> {{ labels['earned'] }} <span class="pl-1">(coming soon)</span> </DropdownItem>
  </Dropdown>
</template>
