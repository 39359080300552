import { UnheadPlugin } from '@unhead/vue/vue2';
import { DropdownPlugin } from 'bootstrap-vue';
import Vue from 'vue';
import LoadScript from 'vue-plugin-load-script';

export function useMiddleware() {
  // Load individual Bootstrap modules still being used, so they can be removed as they are replaced
  Vue.use(DropdownPlugin);

  Vue.use(LoadScript);
  Vue.use(UnheadPlugin);
}
