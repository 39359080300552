<script setup lang="ts">
import type { AwsCostsAvoidedDashboardResponse, Timeframe } from '@console/services/aws/arm/api.models.ts';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

import { useHead } from '@unhead/vue';
import { isAxiosError } from 'axios';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import { getCostAvoidedDashboard } from '@aws/services/awsArmService.ts';
import { useVuexStore } from '@console/state/vuex/store.ts';

import CostsAvoidedMetrics from '@aws/components/arm/CostsAvoided/CostsAvoidedMetrics.vue';
import BuildingState from '@console/components/BuildingState.vue';
import TimeframeSelector from '@console/components/savings/TimeframeSelector.vue';
import PageLayout from '@console/PageLayout.vue';
import PageHeader from '@shared/design/PageHeader.vue';

const router = useRouter();
const store = useVuexStore();

useHead({
  title: 'Costs Avoided',
});

const dashboard = ref<AwsCostsAvoidedDashboardResponse | null>(null);
const isLoading = ref(true);

const awsOrgId = computed(() => store.getters['aws/selectedOrganizationId']);

const onChange = async (nextTimeframe: Timeframe) => {
  const timeframe = nextTimeframe.key;

  isLoading.value = true;

  await router.push({
    name: 'aws_arm_scheduler_costs_avoided',
    params: { timeframe },
    query: router.currentRoute.value.query,
  });
};

watch(
  router.currentRoute,
  async (route: RouteLocationNormalizedLoaded) => {
    // should never really be an array but if it is just grab the first value
    const timeframe = Array.isArray(route.params.timeframe) ? route.params.timeframe[0] : route.params.timeframe;

    isLoading.value = true;
    try {
      dashboard.value = await getCostAvoidedDashboard(awsOrgId.value, timeframe);
    } catch (e) {
      const shouldContinue = handleError(e);
      // prevents the loading from finishing before redirect to 404 page is complete
      if (!shouldContinue) {
        return;
      }
    }
    isLoading.value = false;
  },
  { immediate: true }
);

function handleError(error: unknown): boolean {
  if (isAxiosError(error) && error.response?.status === 404) {
    // they are accessing the default page without a timeframe and we don't have a dashboard
    // set which means we probably haven't generated it yet and they should get the empty state
    if (!router.currentRoute.value.params.timeframe) {
      dashboard.value = null;
      return true;
    } else {
      // the user is trying to access a timeframe that doesn't exist from a bad timeframe in the url
      router.push({ name: '404' });
      return false;
    }
  }
  throw error;
}
</script>
<template>
  <PageLayout :loading="isLoading">
    <template v-if="dashboard" #default>
      <PageHeader wrap-utility>
        <div class="d-flex gap-3 align-items-center mr-2">
          <h1>Costs Avoided</h1>
        </div>
        <template v-slot:utility>
          <TimeframeSelector
            :selected="dashboard.selected_timeframe"
            :timeframes="dashboard.available_timeframes"
            :finalized="dashboard.dashboard.is_finalized ?? false"
            :data-through-date="dashboard.updated_date"
            @change="onChange"
          />
        </template>
      </PageHeader>
      <CostsAvoidedMetrics v-if="dashboard" :data="dashboard.dashboard" />
    </template>
    <template v-else #default>
      <div class="row sectional">
        <div class="col">
          <BuildingState variant="armScheduler" />
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.additionalInfoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
}
</style>
