<script setup lang="ts">
import type { AwsAdmOfferingCode } from '@console/services/api.models';
import type { SolidIconName } from '@shared/design/icons/BaseIcon.vue';

import Big from 'big.js';
import _ from 'lodash';
import { computed, ref } from 'vue';

import {
  isOrganizationComputeDashboard,
  type AwsOrganizationNonComputeSavingsBreakdownPeriod,
  type AwsOrganizationSavingsBreakdownPeriod,
  type AwsSavingsDashboardResponse,
} from '@console/services/aws/savings.models';
import { useVuexStore } from '@console/state/vuex/store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';
import NumberHelpers from '@shared/utilities/number_helpers';

import NormalizedVsActualToggle from './NormalizedVsActualToggle.vue';
import SavingsBreakdownModal from './SavingsBreakdownModal.vue';
import Currency from '@shared/components/Currency.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import TextTooltip from '@shared/design/TextTooltip.vue';

type Breakdown = AwsOrganizationNonComputeSavingsBreakdownPeriod & AwsOrganizationSavingsBreakdownPeriod;
type SavingsBreakdownPeriodField = keyof Breakdown;

type RowConfig = {
  key: SavingsBreakdownPeriodField;
  label: string;
  desc: string;
  showValues: boolean;
  showRow?: boolean;
  class?: string;
};

type Props = {
  savings: AwsSavingsDashboardResponse;
  demo?: boolean;
  service?: AwsAdmOfferingCode;
  allDiscounts?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  demo: false,
  service: 'compute',
});
const showNormalized = ref(true);

type SavingsCategoryConfig = {
  field: SavingsBreakdownPeriodField;
  label: string;
};

const savingsCategories: SavingsCategoryConfig[] = [
  { field: 'base_savings', label: 'Base Savings' },
  { field: 'flex_savings', label: 'Flex Savings' },
  { field: 'flex_boost_savings', label: 'Flex Boost Savings' },
  { field: 'smart_savings', label: 'Smart Savings' },
  { field: 'inherited_savings', label: 'Inherited Savings' },
  { field: 'unbilled_savings', label: 'Unbilled Savings' },
] as const;

const usageCategories: SavingsCategoryConfig[] = [
  { field: 'commitment_discountable_usage', label: 'Commitment Discountable Usage' },
  { field: 'spot_usage', label: 'Spot Usage' },
  { field: 'capacity_block_reservation_usage', label: 'Capacity Block Usage' },
] as const;

const store = useVuexStore();
const isReseller = computed(() => store.getters['customer/isReseller']);
const header = computed(() => `${serviceDisplayName.value} Savings Breakdown`);

const dashboard = computed(() => props.savings.dashboard);

const showSavingsBreakdownModal = ref(false);
const savingsBreakdownPeriods = computed((): Breakdown[] => {
  const periods = props.allDiscounts && isOrganizationComputeDashboard(dashboard.value)
    ? dashboard.value.savings_breakdown_periods_all_discounts!
    : dashboard.value.savings_breakdown_periods;

  return periods.slice().sort((a, b) => a.sort_order - b.sort_order) as Breakdown[];
});

const headings = computed(() => savingsBreakdownPeriods.value.map(v => v.period_title));
const rows = computed(() => {
  return [...getResellerComputeRows(), ...getRows()].filter(r => r.showRow !== false);
});
const serviceDisplayName = computed(() => AwsServiceHelpers.getDisplayName(props.service));
const isProductCompute = computed(() => props.service === 'compute');

const prefixForReseller = (label: string) => {
  if (isReseller.value && isProductCompute.value) {
    return `Arbitrageable ${label}`;
  }
  return label;
};

const usageDescription = () => {
  if (isReseller.value && isProductCompute.value) {
    return 'what you would have paid AWS without discounts for usage not covered by end customer commitments';
  }
  return 'what you would have paid AWS without discounts';
};

const spendDescription = () => {
  if (isReseller.value && isProductCompute.value) {
    return 'what you paid AWS (with prepay amortized) for usage not covered by end customer commitments';
  }
  return 'what you paid AWS, with prepay amortized';
};

const getDelta = (row: RowConfig, period: Breakdown): number => {
  var key = `${row.key}_delta` as keyof Breakdown;
  var value = period[key];
  return typeof value === 'number' ? value : 0;
};

const getDeltaPercentage = (row: RowConfig, period: Breakdown): number => {
  var key = `${row.key}_delta_percentage` as keyof Breakdown;
  var value = period[key];
  return typeof value === 'number' ? value : 0;
};

const showDelta = (row: RowConfig, period: Breakdown) => {
  var key = `${row.key}_delta` as keyof Breakdown;
  var delta = period[key];
  return showNormalized.value && (!!delta || delta === 0);
};

const formatDelta = (delta: number) => {
  const value = NumberHelpers.formatDollars(Math.abs(delta));
  return delta < 0 ? `-${value}` : value;
};

const formatSingleDecimal = (delta: number) => {
  return NumberHelpers.formatNumber(delta, 1);
};

const deltaClasses = (delta: number) => {
  if (delta > 0) {
    return 'positive';
  }
  if (delta < 0) {
    return 'negative';
  }
  return null;
};

const deltaIcon = (delta: number): SolidIconName => {
  if (delta > 0) return 'arrow-up';
  if (delta < 0) return 'arrow-down';
  throw new Error('Invalid delta value');
};

const tooltipConfig = {
  allDiscountUsage: (rowKey: SavingsBreakdownPeriodField, _: Breakdown) => {
    return props.allDiscounts && isProductCompute.value && rowKey === 'compute_usage';
  },
  incrementalSavings: (rowKey: SavingsBreakdownPeriodField, period: Breakdown) => {
    if (rowKey === 'net_savings') {
      return isNumber(period, 'incremental_savings');
    }
    return false;
  },
  billingCategorySavings: (rowKey: SavingsBreakdownPeriodField, period: Breakdown) => {
    if ((!props.allDiscounts && rowKey === 'gross_savings') || (props.allDiscounts && rowKey === 'total_billing_category_savings')) {
      return savingsCategories.some(c => isNumber(period, c.field));
    }
    return false;
  },
} as const;

const tdEmptyState = (rowKey: string, period: Breakdown) => {
  const isFinalized = _.get(period, 'is_finalized', false);
  if (isFinalized) {
    return '-';
  }
  return _.includes(['savings_share', 'net_savings'], rowKey) ? '(not finalized)' : '-';
};

const getValue = (period: Breakdown, field: SavingsBreakdownPeriodField) => {
  if (showNormalized.value) field += '_normalized';
  return period[field];
};

const isNumber = (period: Breakdown, field: SavingsBreakdownPeriodField) => {
  return _.isNumber(getValue(period, field));
};

const getRows = (): RowConfig[] => {
  return [
    {
      key: isProductCompute.value ? 'compute_usage' : 'service_usage',
      label: prefixForReseller(`${serviceDisplayName.value} Usage`),
      desc: usageDescription(),
      showValues: true,
    },
    {
      key: isProductCompute.value ? 'compute_spend' : 'service_spend',
      label: prefixForReseller(`${serviceDisplayName.value} Spend`),
      desc: spendDescription(),
      showValues: true,
    },
    {
      key: 'gross_savings',
      label: 'Gross Savings',
      desc: 'how much you saved on your AWS bill',
      showValues: true,
    },
    {
      key: 'total_billing_category_savings',
      label: 'RI and SP Savings',
      desc: 'how much RIs and SPs saved you, including private rate savings',
      showValues: true,
      showRow: props.allDiscounts,
      class: 'indent',
    },
    {
      key: 'total_non_billing_category_savings',
      label: 'Spot and Other Private Rate Savings',
      desc: 'additional rate optimization savings',
      showValues: true,
      showRow: props.allDiscounts,
      class: 'indent',
    },
    {
      key: 'savings_share',
      label: 'Savings Share',
      desc: props.allDiscounts
        ? 'our share of the RI and SP savings generated'
        : 'our share of the savings generated',
      showValues: !props.demo,
    },
    {
      key: 'net_savings',
      label: 'Net Savings',
      desc: 'your savings net of our charge',
      showValues: !props.demo,
    },
  ];
};

const getResellerComputeRows = (): RowConfig[] => {
  return isReseller.value && isProductCompute.value
    ? [
      {
        key: 'reseller_total_compute_usage',
        label: 'Total Compute Usage',
        desc: 'what you would have paid AWS without discounts',
        showValues: true,
        class: 'resellerRow',
      },
      {
        key: 'reseller_end_customer_spend_coverage',
        label: 'End Customer Spend Coverage',
        desc: 'usage covered by end customer commitments',
        showValues: true,
        class: 'resellerRow',
      },
    ]
    : [];
};

const savingsPercentage = (
  period: Breakdown,
  numeratorField: SavingsBreakdownPeriodField,
  denominatorField: SavingsBreakdownPeriodField
) => {
  const numerator = getValue(period, numeratorField) as number;
  const denominator = getValue(period, denominatorField) as number;

  if (denominator <= 0) {
    return null;
  }

  const percent = NumberHelpers.formatPercent(Number(Big(numerator).div(denominator)), 1);
  return `(${percent})`;
};

const showSavingsBreakdownLink = (rowKey: SavingsBreakdownPeriodField) => props.allDiscounts && rowKey === 'gross_savings';
</script>

<template>
  <PanelSection :header="header" class="savingsBreakdown">
    <template v-slot:utility>
      <NormalizedVsActualToggle :show-normalized="showNormalized" @change="e => (showNormalized = e)" />
    </template>
    <!-- mb-0 is needed to prevent the vertical scrollbar from appearing on the parent panel -->
    <table class="table breakdown mb-0">
      <thead>
        <tr class="tableHeader">
          <th></th>
          <th v-for="heading in headings" :key="heading" class="text-uppercase text-muted font-weight-normal">
            {{ heading }}
          </th>
        </tr>
      </thead>
      <tbody class="tableBody">
        <tr v-for="row in rows" :key="row.key" :class="row.class">
          <th>
            <div>{{ row.label }}</div>
            <small class="d-none d-xl-block description text-muted">{{ row.desc }}</small>
            <small v-if="isOrganizationComputeDashboard(dashboard) && showSavingsBreakdownLink(row.key)">
              <a href="#" @click.prevent="showSavingsBreakdownModal = true">Show me a full breakdown</a>
              <SavingsBreakdownModal
                v-model="showSavingsBreakdownModal"
                :show-normalized="showNormalized"
                :dashboard="dashboard"
              />
            </small>
          </th>
          <td v-for="period in savingsBreakdownPeriods" :key="row.key + period.period_title">
            <div v-if="!row.showValues">-</div>
            <div v-else-if="!getValue(period, row.key)">
              {{ tdEmptyState(row.key, period) }}
            </div>
            <div v-else>
              <TextTooltip placement="bottom" :size="tooltipConfig.allDiscountUsage(row.key, period) ? 'lg' : 'md'">
                <Currency class="trendValue" :value="getValue(period, row.key)" />

                <template v-if="tooltipConfig.billingCategorySavings(row.key, period)" #tooltip>
                  <template v-for="category in savingsCategories">
                    <div v-if="isNumber(period, category.field)" :key="category.field" class="tooltipBaseFlex">
                      <div>{{ category.label }}:</div>
                      <div>
                        <Currency :value="getValue(period, category.field)" />
                        {{ savingsPercentage(period, category.field, 'gross_savings') }}
                      </div>
                    </div>
                  </template>
                </template>

                <template v-else-if="tooltipConfig.incrementalSavings(row.key, period)" #tooltip>
                  Of this amount,
                  <Currency :value="getValue(period, 'incremental_savings')" class="tooltipIncrementalSavings" />
                  is the incremental savings generated by ProsperOps, above and beyond what you would have otherwise
                  saved (assuming you continued achieving your baseline Effective Savings Rate).
                </template>

                <template v-else-if="tooltipConfig.allDiscountUsage(row.key, period)" #tooltip>
                  <template v-for="category in usageCategories">
                    <div v-if="isNumber(period, category.field)" :key="category.field" class="tooltipBaseFlex">
                      <div>{{ category.label }}:</div>
                      <div>
                        <Currency :value="getValue(period, category.field)" />
                        {{ savingsPercentage(period, category.field, 'compute_usage') }}
                      </div>
                    </div>
                  </template>
                </template>
              </TextTooltip>
              <div v-if="showDelta(row, period)">
                <div class="delta" :class="deltaClasses(getDelta(row, period))">
                  <div>
                    <BaseIcon v-if="getDelta(row, period) !== 0" :name="deltaIcon(getDelta(row, period))" />
                  </div>
                  <div>
                    <div v-if="getDelta(row, period) === 0" class="pr-1">
                      <small>
                        -
                        <br />
                        -
                      </small>
                    </div>
                    <div v-else>
                      <small>
                        {{ formatDelta(getDelta(row, period)) }}
                        <br />
                        {{ formatSingleDecimal(getDeltaPercentage(row, period)) }}%
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

table.breakdown {
  font-size: 12px;

  th,
  td {
    padding: 6px;
  }

  @include media-breakpoint-up(md) {
    font-size: inherit;

    th,
    td {
      padding: 12px;
    }
  }
}

.tableHeader th {
  text-align: right;
  background-color: $table-head-background;
  border-top: 0;
  border-bottom: 0;
}

.tableBody > tr > th {
  width: 60%;
}

.tableBody > tr > td,
.tableBody > tr > th {
  font-weight: 400;
}

.tableBody > tr:first-child > td,
.tableBody > tr:first-child > th {
  border-top: 0;
}

.tableBody td {
  text-align: right;
}

.tableBody > tr.indent th {
  padding-left: 1.8rem;
}

.tableBody > tr.indent th,
.tableBody > tr.indent td {
  font-size: 0.85rem;
  background-color: $gray-100;
  border-top: 0;
  border-bottom: 1px solid $gray-200;
}

.tableBody td > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.tableBody tr:last-child td,
.tableBody tr:last-child th {
  background-color: lighten(map-get($theme-colors, 'secondary'), 15%);
  border-top: 0;
  border-bottom: 0;
}

.tableBody tr:last-child th > div,
.tableBody tr:last-child td .trendValue {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 1.1rem;
  }
}

.tableBody > tr > td:last-child .trendValue {
  font-weight: 500;
}

.resellerRow:nth-of-type(2) > th,
.resellerRow:nth-of-type(2) > td {
  padding-bottom: 14px;
  border-bottom: 3px solid map-get($theme-colors, 'light');
}

/* stylelint-disable selector-max-type */
.resellerRow:nth-of-type(2) + tr > th,
.resellerRow:nth-of-type(2) + tr > td {
  padding-top: 14px;
}

/* stylelint-enable selector-max-type */

.delta {
  display: flex;
}

.delta small {
  font-weight: 500;
}

.delta.positive {
  color: map-get($theme-colors, 'success');
}

.delta.negative {
  color: map-get($theme-colors, 'danger');
}

.delta > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
}

.description {
  max-width: 600px;
}

.tooltipBaseFlex {
  font-size: 12px;

  > div {
    display: inline-block;
  }

  > div:first-child {
    padding-right: 0.25rem;
  }

  > div > span {
    font-weight: bold;
  }
}

.tooltipIncrementalSavings {
  font-weight: bold;
}
</style>
