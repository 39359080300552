<script lang="ts" setup>
import type { ReallocationMethodType } from '@aws/components/adm/showback/ReallocationMethod.vue';
import type { SavingsShareType } from '@aws/components/adm/showback/SavingsShare.vue';
import type { SelectedServiceType } from '@aws/components/adm/showback/SelectedService.vue';

import { defineProps, defineEmits, computed } from 'vue';

import { useFeatureStore } from '@shared/state/feature.store';

import ReallocationMethod from '@aws/components/adm/showback/ReallocationMethod.vue';
import SavingsShare from '@aws/components/adm/showback/SavingsShare.vue';
import SelectedService from '@aws/components/adm/showback/SelectedService.vue';
import DownloadCsvIcon from '@shared/design/icons/DownloadCsvIcon.vue';

const reallocationMethod = defineModel<ReallocationMethodType>('reallocationMethod', { required: true });
const savingsShare = defineModel<SavingsShareType>('savingsShare', { required: true });
const selectedService = defineModel<SelectedServiceType>('selectedService', { required: true });
const props = defineProps<{
  demo?: boolean;
  enabledServices?: SelectedServiceType[];
}>();

const emits = defineEmits<{
  (e: 'download-csv'): void;
}>();

const downloadCSV = () => emits('download-csv');

const showServiceFiltering = computed(() => useFeatureStore().featureFlags.nonComputeIntelligentShowback);
</script>

<template>
  <div class="pb-1 d-flex justify-content-between">
    <div class="actions">
      <div v-if="showServiceFiltering">
        <strong>Service</strong>
        <SelectedService v-model="selectedService" :enabled-services="props.enabledServices" />
      </div>
      <div>
        <strong>Savings Reallocation Method</strong>
        <ReallocationMethod v-model="reallocationMethod" :disabled="props.demo" />
      </div>
      <div>
        <strong>ProsperOps Savings Share</strong>
        <SavingsShare v-model="savingsShare" :disabled="props.demo" />
      </div>
    </div>
    <div>
      <BaseButton
        title="Download in CSV format"
        variant="transparent"
        class="csvIcon btn-no-focus-box-shadow"
        @click.prevent="downloadCSV"
      >
        <DownloadCsvIcon />
      </BaseButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.actions {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.csvIcon > svg {
  width: 24px;
  height: 24px;
}
</style>
