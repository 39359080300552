<script lang="ts" setup>
import { computed } from 'vue';

import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import Dropdown from '@shared/design/dropdowns/Dropdown.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

export type SelectedServiceType = 'all' | 'compute' | 'rds' | 'elasti_cache' | 'open_search' | 'memory_db' | 'redshift';

const model = defineModel<SelectedServiceType>({ required: true });
const props = defineProps<{
  enabledServices?: SelectedServiceType[];
}>();

const options = computed(() => {
  if (props.enabledServices) {
    return props.enabledServices.map(service => {
      return { value: service, label: AwsServiceHelpers.getDisplayName(service) };
    });
  }

  return [
    { value: 'all', label: 'All' },
    { value: 'compute', label: 'Compute' },
  ];
});
const selectedLabel = computed(() => options.value.find(o => o.value === model.value)?.label);
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <Dropdown variant="transparent" toggle-class="rounded-0" dropdown-class="rounded-0" dropdown-placement="bottom-start">
    <template #toggleContent>
      {{ selectedLabel }}
    </template>
    <DropdownItem v-for="o in options" :key="o.value" @click="model = o.value as SelectedServiceType">
      {{ o.label }}
    </DropdownItem>
  </Dropdown>
</template>
