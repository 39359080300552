<script setup lang="ts">
import type { OptionsArray } from '@console/components/filters/types';
import type { RegionResponse } from '@console/services/api.models';

import { computed } from 'vue';

import RegionName from '@aws/components/RegionName.vue';
import FilterContainer from '@console/components/filters/FilterContainer.vue';
import DropdownDivider from '@shared/design/dropdowns/DropdownDivider.vue';
import DropdownGroup from '@shared/design/dropdowns/DropdownGroup.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

const props = defineProps<{
  modelValue?: RegionResponse | null;
  regions: OptionsArray<RegionResponse>;
}>();

const emit = defineEmits(['update:modelValue']);

const selectedRegion = computed(() => {
  return props.modelValue;
});

const onClick = (region?: RegionResponse) => {
  emit('update:modelValue', region);
};
</script>

<template>
  <FilterContainer label="Region" type="region">
    <template #selected-value>
      <span v-if="!selectedRegion">All Regions</span>
      <RegionName v-else :region="selectedRegion" />
    </template>

    <DropdownItem @click="onClick()">All Regions</DropdownItem>
    <DropdownDivider />
    <template v-for="region in props.regions" :key="region">
      <!-- if it's a RegionResponse object -->
      <DropdownItem v-if="'system_name' in region" @click="onClick(region)">
        <RegionName :region="region" />
      </DropdownItem>
      <!-- if it's a group -->
      <template v-else>
        <DropdownGroup :id="region.label" :header="region.label">
          <DropdownItem
            v-for="regionValue in region.values"
            :key="regionValue.system_name"
            class="grouped"
            @click="onClick(regionValue)"
          >
            <RegionName :region="regionValue" />
          </DropdownItem>
        </DropdownGroup>
      </template>
    </template>
  </FilterContainer>
</template>
