<script setup lang="ts">
const props = defineProps<{
  variant?: 'showback' | 'armScheduler';
}>();
</script>

<template>
  <div>
    <div>
      <div class="buildingState">
        <div class="text-center">
          <h1 class="mt-1 display-4">Crunching the numbers...</h1>
        </div>
        <template v-if="props.variant === 'showback'">
          <p class="lead text-center mb-0 mt-1">
            Your first Showback report will be available once AWS finalizes data for your initial month of ProsperOps
            service. In the meantime, if you have any questions regarding your configuration or savings outcomes, please
            <router-link :to="{ name: 'help' }">contact us</router-link>.
          </p>
        </template>
        <template v-if="props.variant === 'armScheduler'">
          <p class="lead text-center mb-0 mt-1">
            Your first dashboard will be available 48-72 hours after enabling the resource scheduler. In the meantime,
            if you have any questions regarding configuration please
            <router-link :to="{ name: 'help' }">contact us</router-link>.
          </p>
        </template>
        <template v-if="!props.variant">
          <p class="lead text-center mb-0 mt-1">
            Your first dashboard will be available 48-72 hours after your onboarding conversation with our team. In the
            meantime, if you have any questions regarding your configuration or savings outcomes, please
            <router-link :to="{ name: 'help' }">contact us</router-link>.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.buildingState {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;

  > p {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
}
</style>
