<script lang="ts" setup>
import type { AwsSavingsDashboard, AwsOrganizationComputeSavingsDashboard } from '@console/services/aws/savings.models';

import { computed, ref } from 'vue';

import SavingsMetric from './SavingsMetric.vue';
import CyclicalWaveIcon from '@shared/design/icons/CyclicalWaveIcon.vue';
import Modal from '@shared/design/modals/Modal.vue';

const props = defineProps<{
  dashboard: AwsSavingsDashboard;
  useSmallerText: boolean;
}>();

const showModal = ref(false);

const utilizationPercentage = computed(() => props.dashboard?.key_metrics.reserved_instance_utilization_percentage);
const cyclicalityDetected = computed(
  () => (props.dashboard as AwsOrganizationComputeSavingsDashboard)?.ec2_cyclicality_detected ?? false
);
</script>

<template>
  <SavingsMetric
    name="reserved_instance_utilization_percentage"
    variant="primary"
    :use-smaller-text="useSmallerText"
    :value="utilizationPercentage"
  >
    <template v-if="cyclicalityDetected" v-slot:value-icon>
      <a href="#" class="wave" @click.prevent="showModal = true"><CyclicalWaveIcon /></a>
      <Modal v-model="showModal" size="md" title="Cyclical Usage Detected">
        <div class="pb-3">
          <span>
            Our platform has detected cyclical usage patterns and is using advanced strategies to maximize your savings.
            These techniques may lower your Reserved Instance utilization rate but improve your Effective Savings Rate.
          </span>
          <a
            href="https://www.prosperops.com/blog/how-to-maximize-cloud-savings-on-cyclical-workloads-using-commitment-based-discounts/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-nowrap"
            >Learn more</a
          >
        </div>
      </Modal>
    </template>
  </SavingsMetric>
</template>

<style lang="scss" scoped>
.wave {
  margin-left: 0.25rem;
  line-height: 1;

  > svg {
    height: 18px;
  }
}
</style>
