<script>
import _ from 'lodash';
import { mapActions } from 'vuex';

import customerService from '@console/services/customerService';

import Layout from '@console/Layout.vue';

export default {
  components: {
    Layout,
  },
  data() {
    return {
      success: null,
    };
  },
  head: {
    title: 'AWS Marketplace',
  },
  computed: {
    isLoading() {
      const data = [this.success];
      return _.some(data, _.isNull);
    },
  },
  async mounted() {
    try {
      const response = await customerService.resolveAwsMarketplaceToken(this.$route.query.token);
      const successfulResolve = response.data.success;

      if (successfulResolve) {
        await this.refreshCompany();
      }

      this.success = successfulResolve;
    } catch {
      this.success = false;
    }
  },
  methods: {
    ...mapActions('customer', { refreshCompany: 'refresh' }),
  },
};
</script>

<template>
  <Layout :loading="isLoading" wide :with-announcement-banner="false">
    <template #default>
      <div class="announcement pt-4">
        <template v-if="success">
          <div class="pt-2 pb-2 text-center">
            <h1 class="display-4">Thanks!</h1>
          </div>
          <p class="lead text-center mb-0 w-50">
            We have successfully processed your AWS Marketplace subscription details. You can now
            <router-link :to="{ name: 'root' }">continue the onboarding process</router-link>.
          </p>
        </template>
        <template v-else>
          <p class="lead text-center mb-0 w-50">
            We encountered an error processing your AWS Marketplace subscription. We'll be in touch shortly to discuss
            next steps.
          </p>
        </template>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
}
</style>
