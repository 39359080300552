<script setup lang="ts">
import type { ComputeSavingsDashboardResponse } from '@azure/services/savings.models';
import type { MonthStart } from '@console/services/api.models';

import _ from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';

import { savingsDashboard } from '@azure/services/billingScope';
import { useAzureStore } from '@azure/state';
import { useAuthStore } from '@shared/state/auth.store';
import { useFeatureFlag } from '@shared/state/feature.store';
import { singleParamValue } from '@shared/utilities/routing';

import Savings from './Savings.vue';
import BuildingState from '@console/components/BuildingState.vue';
import PageLayout from '@console/PageLayout.vue';

const generateSavingsDashboard = useFeatureFlag('azureSavingsDashboardGenerateOnDemand');

const router = useRouter();
const azureStore = useAzureStore();

const selectedTimeframe = ref<MonthStart>();
const savingsDashboardNotFound = ref(false);
const savings = ref<ComputeSavingsDashboardResponse | undefined>(undefined);

const userIsEmployee = computed(() => useAuthStore().isEmployee);

const isLoading = computed(() => !selectedTimeframe.value && !savingsDashboardNotFound.value);

onBeforeRouteUpdate(async (to, _, next) => {
  await load(singleParamValue(to.params, 'timeframe'));
  next();
});

onMounted(async () => {
  await load(singleParamValue(router.currentRoute.value.params, 'timeframe'));
});

const savingsTimeframes = computed(() => savings.value?.available_timeframes ?? ([] as MonthStart[]));

const load = async (timeframe: string) => {
  try {
    const billingProfileId = azureStore.billingScopeId;
    const result = await savingsDashboard(billingProfileId, timeframe, generateSavingsDashboard.value);
    if (userIsEmployee.value || result.customer_visible) {
      savings.value = result;
    } else {
      savings.value = undefined;
    }
    if (timeframe) {
      selectedTimeframe.value = savingsTimeframes.value.find(x => x.key === timeframe);
    } else if (savingsTimeframes.value.length > 0) {
      selectedTimeframe.value = savingsTimeframes.value[0];
    } else {
      selectedTimeframe.value = undefined;
    }
  } catch (e) {
    handleError(e, timeframe);
  }
};

const handleError = async (e: unknown, timeframe: string) => {
  console.error(e, timeframe);
  const status = _.get(e, 'response.status', 500) as number | undefined;
  if (status === 404) {
    if (!timeframe) {
      savingsDashboardNotFound.value = true;
      selectedTimeframe.value = undefined;
    } else {
      await router.push({ name: '404' });
    }
  } else {
    await router.push({ name: 'error' });
  }
};
</script>

<template>
  <PageLayout :loading="isLoading">
    <template #default>
      <BuildingState v-if="savingsDashboardNotFound" />
      <Savings
        v-else-if="savings && selectedTimeframe"
        :savings="savings"
        :selected-timeframe="selectedTimeframe"
        :savings-dashboard-not-found="savingsDashboardNotFound"
      />
    </template>
  </PageLayout>
</template>
