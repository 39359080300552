<script setup lang="ts">
import type { CostsAvoidedDashboard } from '@console/services/aws/arm/api.models';

import MetricPanel from '@shared/design/panels/MetricPanel.vue';

const props = defineProps<{
  data: CostsAvoidedDashboard;
}>();

interface Metric {
  name: string;
  field: keyof CostsAvoidedDashboard;
  unit: string;
  color: string;
}

const metrics: Metric[] = [
  {
    name: 'Power State Changes',
    field: 'successful_state_change_count',
    unit: 'count',
    color: 'warning',
  },
  {
    name: 'Actively Managed Resources',
    field: 'successful_state_change_unique_instance_count',
    unit: 'count',
    color: 'success',
  },
];
</script>

<template>
  <div class="row sectional">
    <div v-for="metric in metrics" :key="metric.name" class="col-sm-12 col-md-6">
      <MetricPanel :name="metric.name" :color="metric.color">
        <div>{{ props.data[metric.field] }}</div>
      </MetricPanel>
    </div>
  </div>
</template>
