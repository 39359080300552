<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed } from 'vue';

import { LayoutClassName } from '@shared/layout';
import { Cloud } from '@shared/state/auth.store';

import ResourceSelectorHeader from '@console/components/users/ResourceSelectorHeader.vue';
import AwsLogoDark from '@shared/design/icons/AwsLogoDark.vue';
import AzureLogo from '@shared/design/icons/AzureLogo.vue';
import GcpLogo from '@shared/design/icons/GcpLogo.vue';
import StatusIndicator from '@shared/design/icons/StatusIndicator.vue';

export interface Resource {
  value: string;
  displayName: string;
  status: string;
  providerId: string;
  cloud: Cloud;
}

interface Props {
  selectedResourceIds: Set<string>;
  selectedResourceId: string | null;
  awsResources: Resource[];
  gcpResources: Resource[];
  azureResources: Resource[];
}

const props = defineProps<Props>();

const emit = defineEmits(['update:selectedResourceId']);

const searchRef = ref<HTMLInputElement | null>(null);

const selectedResourceDisplayName = computed(() => {
  const resource =
    props.awsResources.find(resource => resource.value === props.selectedResourceId) ??
    props.gcpResources.find(resource => resource.value === props.selectedResourceId) ??
    props.azureResources.find(resource => resource.value === props.selectedResourceId);
  return resource?.displayName ?? '';
});

const selectedResourceCloud = computed(() => {
  const resource =
    props.awsResources.find(resource => resource.value === props.selectedResourceId) ??
    props.gcpResources.find(resource => resource.value === props.selectedResourceId) ??
    props.azureResources.find(resource => resource.value === props.selectedResourceId);
  return resource?.cloud;
});

const hasSelectableItems = computed(() => {
  return (
    (props.awsResources.length > 0 || props.gcpResources.length > 0 || props.azureResources.length > 0) &&
    props.awsResources.length + props.gcpResources.length + props.azureResources.length > props.selectedResourceIds.size
  );
});

const resourceSearchText = ref('');
function search(resource: Resource) {
  return (
    resource.displayName.toLowerCase().includes(resourceSearchText.value.toLowerCase()) ||
    resource.providerId.toLowerCase().includes(resourceSearchText.value.toLowerCase())
  );
}
const filteredAwsResources = computed(() => {
  if (resourceSearchText.value) {
    return props.awsResources.filter(search);
  }
  return props.awsResources;
});
const filteredGcpResources = computed(() => {
  if (resourceSearchText.value) {
    return props.gcpResources.filter(search);
  }
  return props.gcpResources;
});
const filteredAzureResources = computed(() => {
  if (resourceSearchText.value) {
    return props.azureResources.filter(search);
  }
  return props.azureResources;
});
function resetSearch() {
  resourceSearchText.value = '';
  searchRef.value?.focus();
}

const toggleDropdownItems = ref(false);
const componentRef = ref<HTMLElement | null>(null);
function handleOutsideClick(event: MouseEvent) {
  const target = event.target as HTMLElement;
  if (!componentRef.value?.contains(target)) {
    toggleDropdownItems.value = false;
  }
}
function onToggleResourceSelectorDropdown() {
  toggleDropdownItems.value = !toggleDropdownItems.value;
  resetSearch();
  const layoutEle = document.getElementsByClassName(LayoutClassName)[0];
  if (layoutEle) {
    setTimeout(() => {
      // setTimeout needed to let the dropdown items render so it increases the height of the layout element before scrolling
      layoutEle.scrollTo({ top: layoutEle.scrollHeight, behavior: 'smooth' });
    }, 1);
  }
}
function onItemSelection(resourceId: string) {
  emit('update:selectedResourceId', resourceId);
  toggleDropdownItems.value = false;
}
onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});
onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>

<template>
  <div ref="componentRef">
    <div class="d-flex align-items-center">
      <div class="icon" :class="{ [selectedResourceCloud ?? '']: true }">
        <AwsLogoDark v-if="selectedResourceCloud === Cloud.AWS" />
        <AzureLogo v-if="selectedResourceCloud === Cloud.AZURE" />
        <GcpLogo v-if="selectedResourceCloud === Cloud.GCP" />
      </div>
      <button
        type="button"
        class="btn btn-default toggleResourceSelectorDropdown"
        @click="onToggleResourceSelectorDropdown()"
      >
        {{ selectedResourceDisplayName }}
      </button>
      <BaseIcon class="caret" name="angle-down" />
    </div>
    <div v-if="toggleDropdownItems" class="dropdownItemsContainer">
      <div v-if="hasSelectableItems" class="search">
        <input
          ref="searchRef"
          v-model="resourceSearchText"
          type="text"
          class="searchInput form-control"
          placeholder="Search"
        />
        <BaseIcon class="searchIcon" name="search" />
      </div>
      <ResourceSelectorHeader v-if="props.awsResources.length > 0" text="Amazon Web Services" :cloud="Cloud.AWS" />
      <button
        v-for="resource in filteredAwsResources"
        :key="resource.value"
        role="button"
        class="btn btn-default dropdownItem"
        :disabled="props.selectedResourceIds.has(resource.value)"
        @click="onItemSelection(resource.value)"
      >
        <div class="mr-3">
          <StatusIndicator :status="resource.status" />
        </div>
        <div>
          <div class="resourceText" :title="resource.displayName">{{ resource.displayName }}</div>
          <div class="resourceSubText">{{ resource.providerId }}</div>
        </div>
      </button>
      <ResourceSelectorHeader v-if="props.gcpResources.length > 0" text="Google Cloud" :cloud="Cloud.GCP" />
      <button
        v-for="resource in filteredGcpResources"
        :key="resource.value"
        role="button"
        class="btn btn-default dropdownItem"
        :disabled="props.selectedResourceIds.has(resource.value)"
        @click="onItemSelection(resource.value)"
      >
        <div class="mr-3">
          <StatusIndicator :status="resource.status" />
        </div>
        <div>
          <div class="resourceText" :title="resource.displayName">{{ resource.displayName }}</div>
          <div class="resourceSubText">{{ resource.providerId }}</div>
        </div>
      </button>
      <ResourceSelectorHeader v-if="props.azureResources.length > 0" text="Azure" :cloud="Cloud.AZURE" />
      <button
        v-for="resource in filteredAzureResources"
        :key="resource.value"
        role="button"
        class="btn btn-default dropdownItem"
        :disabled="props.selectedResourceIds.has(resource.value)"
        @click="onItemSelection(resource.value)"
      >
        <div class="mr-3">
          <StatusIndicator :status="resource.status" />
        </div>
        <div>
          <div class="resourceText" :title="resource.displayName">{{ resource.displayName }}</div>
          <div class="resourceSubText">{{ resource.providerId }}</div>
        </div>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import '@shared/scss/colors.scss';

.caret {
  margin-left: -15px;
}

.dropdown {
  height: 36px;
  border: 1px solid $gray-400;
  border-radius: 4px;
}

.search {
  position: relative;
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  background-color: $white;
}

.searchIcon {
  position: absolute;
  top: calc((1.5em + 0.75rem + 2px) / 2);
  left: 1.25rem;
  margin-top: 1px;
  color: $gray-600;
}

.searchInput {
  padding-left: calc(1em + 1.25rem);
}

.toggleResourceSelectorDropdown {
  width: 100%;
  max-width: 325px;
  height: 36px;
  padding-bottom: 6px;
  overflow: hidden;
  color: black;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: white;
  border: 1px solid $gray-400;
}

.dropdownItemsContainer {
  position: absolute;
  left: 115px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  padding-top: 20px;
  margin-top: 5px;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
}

.dropdownItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 3rem;
  padding: 0.25rem 1rem;
  text-decoration: none;
  background-color: white;
  border: 0;
  opacity: 1;

  &[disabled] {
    color: rgb(0, 0, 0, 0.5);
    pointer-events: none;
    cursor: not-allowed;
  }
}

.dropdownItem:active,
.dropdownItem:focus,
.dropdownItem:hover {
  color: black;
  background-color: $gray-200;
  outline: 0;
}

.resourceText {
  max-width: 250px;
  overflow: hidden;
  font-weight: 400;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resourceSubText {
  font-size: 0.9rem;
  font-weight: 300;
  text-align: left;
}

.icon {
  width: 32px;
  margin-right: 1rem;

  > svg {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

.icon.aws {
  flex-basis: 2rem;
  min-width: 2rem;
  margin-top: 4px;
}

.icon.azure {
  flex-basis: 1.4rem;
  min-width: 2rem;
}

.icon.gcp {
  flex-basis: 1.5rem;
  min-width: 2rem;
  margin-top: 2px;
}
</style>
