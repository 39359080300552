<script lang="ts" setup>
import { computed } from 'vue';

import Dropdown from '@shared/design/dropdowns/Dropdown.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

type CliType = 'LinuxMacUnix' | 'PowerShell' | 'WindowsCommandPrompt';

const props = defineProps<{
  value: CliType;
}>();

const options: { value: CliType; label: string }[] = [
  { value: 'LinuxMacUnix', label: 'Linux, macOS, or Unix' },
  { value: 'PowerShell', label: 'Powershell' },
  { value: 'WindowsCommandPrompt', label: 'Windows Command Prompt' },
];

const selectedLabel = computed(() => options.find(option => option.value === props.value)?.label);
</script>

<template>
  <Dropdown variant="transparent" dropdown-placement="bottom-end" dropdown-class="rounded-sm">
    <template #toggleContent>
      <small>{{ selectedLabel }}</small>
    </template>
    <DropdownItem v-for="o in options" :key="o.value" @click="$emit('input', o.value)">
      {{ o.label }}
    </DropdownItem>
  </Dropdown>
</template>
