import type { RouteRecordRaw } from 'vue-router';

import { useAzureStore } from '@azure/state';
import {
  atLeastRole,
  hasRoles,
  notInDemo,
  requires,
  selectedCompany,
  userIsEmployee,
} from '@console/router/requirements';
import { getVuexStore } from '@console/state/vuex/store';
import { PermissionLevel } from '@shared/state/auth.store';

import ComputeSettings from '@azure/views/ComputeSettings.vue';
import Marketplace from '@azure/views/Marketplace.vue';
import Onboarding from '@azure/views/Onboarding.vue';
import ComputeSavings from '@azure/views/SavingsDataLoader.vue';

const isBillingScopeActive = () => {
  const vuexStore = getVuexStore();
  const azureStore = useAzureStore();

  const id = vuexStore.state.nav?.context?.id;
  return azureStore.isBillingScopeActive(id);
};

const isComputeSavingsVisible = () => {
  return isBillingScopeActive() || userIsEmployee();
};

export const routes: RouteRecordRaw[] = [
  {
    path: '/azure/compute/savings/:timeframe?',
    name: 'azure_compute_savings',
    component: ComputeSavings,
    beforeEnter: requires(hasRoles, selectedCompany, atLeastRole(PermissionLevel.Viewer), isComputeSavingsVisible),
    meta: {
      initModules: true,
      useNewLayout: true,
    },
  },
  {
    path: '/azure/compute/settings/:timeframe?',
    name: 'azure_compute_settings',
    component: ComputeSettings,
    beforeEnter: requires(hasRoles, selectedCompany, atLeastRole(PermissionLevel.Viewer), isBillingScopeActive),
    meta: {
      initModules: true,
      useNewLayout: true,
    },
  },
  {
    path: '/azure/onboarding',
    name: 'azure_onboarding',
    component: Onboarding,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole(PermissionLevel.Viewer)),
    meta: {
      initModules: true,
      useNewLayout: true,
      layout: {
        noAnnouncementBanner: true,
      },
    },
  },
  {
    path: '/azure/marketplace',
    name: 'azure_marketplace',
    component: Marketplace,
    beforeEnter: requires(notInDemo, hasRoles, selectedCompany, atLeastRole(PermissionLevel.Viewer)),
    meta: {
      initModules: true,
      useNewLayout: true,
      layout: {
        noAnnouncementBanner: true,
      },
    },
  },
];
