<script setup lang="ts">
import type { CustomerDataExportStatusResponse } from '@console/services/api.models';

import { useHead } from '@unhead/vue';
import { onMounted, ref } from 'vue';

import awsService from '@aws/services/awsService';
import { useVuexStore } from '@console/state/vuex/store';

import DataExport from '@aws/components/settings/data-export/DataExport.vue';
import PageLayout from '@console/PageLayout.vue';
import PageHeader from '@shared/design/PageHeader.vue';

const vuexStore = useVuexStore();

useHead({
  title: 'AWS Organization Settings',
});

const isLoading = ref(true);
const initalDataExportState = ref<CustomerDataExportStatusResponse | null>(null);

onMounted(async () => {
  isLoading.value = true;

  initalDataExportState.value = await awsService.getCustomerDataExportStatus(
    vuexStore.getters['aws/selectedOrganizationId']
  );

  isLoading.value = false;
});
</script>

<template>
  <PageLayout :loading="isLoading" with-footer>
    <template #default>
      <div>
        <PageHeader>
          <h1>AWS Organization Settings</h1>
        </PageHeader>
        <div class="row sectional">
          <div class="col">
            <DataExport :initial-data-export-status="initalDataExportState" />
          </div>
        </div>
      </div>
    </template>
  </PageLayout>
</template>
