<script lang="ts" setup generic="TValue extends string">
import Dropdown from '@shared/design/dropdowns/Dropdown.vue';

const props = defineProps<{
  label?: string;
  type?: string;
  value?: TValue;
}>();

const emit = defineEmits<{
  (e: 'shown'): void;
}>();
</script>

<template>
  <div>
    <Dropdown
      class="filter"
      variant="input"
      :class="{ [`filter--${props.type}`]: props.type }"
      dropdown-placement="bottom-start"
      @shown="emit('shown')"
    >
      <template #toggleContent>
        <div class="d-inline-flex align-items-center gap-2">
          <span v-if="props.label" class="text-body d-inline-block mr-2">{{ props.label }}:</span>
          <slot name="selected-value">{{ props.value }}</slot>
        </div>
      </template>
      <slot />
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

:deep(.filter) {
  /* stylelint-disable selector-class-pattern */ // These classes come from bootstrap
  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    text-align: left;

    &::after {
      margin-left: 0.5rem;
    }
  }

  .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
  }

  .dropdown-item {
    padding-right: 1rem;
    padding-left: 1rem;

    &:active {
      color: $gray-900;
      background-color: $gray-200;
    }
  }

  .dropdown-header {
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 75%;
    font-weight: bold;
    text-transform: uppercase;
  }
  /* stylelint-enable selector-class-pattern */

  .filterSearch {
    position: relative;
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    background-color: $white;

    /* stylelint-disable selector-class-pattern */ // These classes come from bootstrap
    .form-control {
      padding-left: calc(1em + 1.25rem);
    }

    .svg-inline--fa {
      position: absolute;
      top: calc((1.5em + 0.75rem + 2px) / 2);
      left: 1.25rem;
      margin-top: 1px;
      color: $gray-600;
    }
    /* stylelint-enable selector-class-pattern */
  }
}
</style>
