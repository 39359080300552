import _ from 'lodash';
import moment from 'moment';

const encode = val => (!val ? val : `"${val.replaceAll('"', '""')}"`);

const saveFile = (filename, rows) => {
  const lines = rows.join('\n');
  const csvContent = `data:text/csv;charset=utf-8,${lines}`;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const showbackHeader = () => {
  return [
    'account_number',
    'account_name',
    'service',
    'region',
    'platform',
    'tenancy',
    'instance_type',
    'undiscounted_usage',
    'original_savings',
    'original_cost',
    'reallocated_savings',
    'adjusted_cost',
    'net_adjustment',
  ].join(',');
};

const showbackLine = row => {
  return [
    row.aws_account_number,
    encode(row.friendly_name),
    encode(row.service),
    encode(row.region),
    encode(row.platform),
    encode(row.tenancy),
    encode(row.instance_type),
    row.undiscounted_usage,
    row.original_savings,
    row.original_cost,
    row.reallocated_savings,
    row.adjusted_cost,
    row.net_adjustment,
  ].join(',');
};

const showbackLines = showbackRows => {
  return _(showbackRows).map(showbackLine).join('\n');
};

const showbackFileName = (selectedTimeframe, managementAccount, filter, showForNonCompute = false) => {
  const method = filter.reallocation_method;
  const share = filter.savings_share;
  const service = filter.service;
  const filenameComponents = [
    'prosperops-showback',
    selectedTimeframe.key,
    managementAccount.aws_account_number,
    method === 'shared' ? 'shared_benefit' : 'earned_benefit',
    share === 'include' ? 'savings_share' : 'no_savings_share',
  ];
  if (showForNonCompute) {
    filenameComponents.push(service === 'all' ? 'all_services' : service);
  }
  const filename = filenameComponents.join('-');
  return `${filename}.csv`;
};

const showbackRows = showbackRows => {
  return _.concat([], showbackHeader(), showbackLines(showbackRows));
};

const organizationSummaryFileName = (selectedTimeframe, company) => {
  const customerName = _.lowerCase(company.company_name).replaceAll(' ', '_');
  const filename = ['prosperops-multi-organization-summary', customerName, selectedTimeframe.key, moment().unix()].join(
    '-'
  );
  return `${filename}.csv`;
};

const organizationSummaryHeader = (isReseller, variant, hasUnbilledSavings) => {
  const grossSavingsCategoryHeaders =
    variant === 'AwsComputeBaseFlexSmart'
      ? ['gross_savings_base', 'gross_savings_flex', 'gross_savings_flex_boost', 'gross_savings_smart']
      : variant === 'AwsComputeInheritedBaseSmart'
      ? ['gross_savings_inherited', 'gross_savings_base', 'gross_savings_smart']
      : ['gross_savings_base', 'gross_savings_flex', 'gross_savings_flex_boost'];

  const headers = [
    'organization_name',
    'management_aws_account_number',
    'effective_savings_rate',
    'baseline_effective_savings_rate',
    ...organizationSummaryResellerRows(isReseller),
    resellerPrefix(isReseller, 'compute_usage'),
    resellerPrefix(isReseller, 'compute_usage_normalized_monthly_change_dollar'),
    resellerPrefix(isReseller, 'compute_usage_normalized_monthly_change_percent'),
    resellerPrefix(isReseller, 'compute_spend'),
    'gross_savings',
    // Unbilled Savings only apply to a handful of customers, so only include the column if there are unbilled savings
    hasUnbilledSavings && 'gross_savings_unbilled',
    ...grossSavingsCategoryHeaders,
    'savings_share',
    'net_savings',
    'net_savings_normalized_monthly_change_dollar',
    'net_savings_normalized_monthly_change_percent',
    'incremental_savings',
    'lifetime_savings',
    'incremental_lifetime_savings',
  ];

  return headers.filter(v => !!v).join(',');
};

const organizationSummaryResellerRows = isReseller => {
  if (isReseller) {
    return [
      'total_compute_usage',
      'total_compute_usage_normalized_monthly_change_dollar',
      'total_compute_usage_normalized_monthly_change_percent',
      'end_customer_spend_coverage',
      'end_customer_spend_coverage_normalized_monthly_change_dollar',
      'end_customer_spend_coverage_normalized_monthly_change_percent',
    ];
  }
  return [];
};

const resellerPrefix = (isReseller, header) => {
  if (isReseller) {
    return `arbitrageable_${header}`;
  }
  return header;
};

const organizationSummaryRows = (isReseller, variant, showNormalized, isDemo, organizationSummaries) => {
  const hasUnbilledSavings = organizationSummaries.some(summary => !!summary.unbilled_savings);

  return [
    organizationSummaryHeader(isReseller, variant, hasUnbilledSavings),
    ...organizationSummaryLines(isReseller, variant, hasUnbilledSavings, showNormalized, isDemo, organizationSummaries),
  ];
};

const organizationSummaryResellerColumns = isReseller => {
  if (isReseller) {
    return [
      { name: 'reseller_total_compute_usage', hasNormalized: true },
      { name: 'reseller_total_compute_usage_delta', isDelta: true },
      { name: 'reseller_total_compute_usage_delta_percentage', isDelta: true },
      { name: 'reseller_end_customer_spend_coverage', hasNormalized: true },
      { name: 'reseller_end_customer_spend_coverage_delta', isDelta: true },
      { name: 'reseller_end_customer_spend_coverage_delta_percentage', isDelta: true },
    ];
  }
  return [];
};

const organizationSummaryLine = (
  isReseller,
  variant,
  hasUnbilledSavings,
  showNormalized,
  isDemo,
  organizationSummary
) => {
  const grossSavingsCategoryFields =
    variant === 'AwsComputeBaseFlexSmart'
      ? ['base_savings', 'flex_savings', 'flex_boost_savings', 'smart_savings']
      : variant === 'AwsComputeInheritedBaseSmart'
      ? ['inherited_savings', 'base_savings', 'smart_savings']
      : ['base_savings', 'flex_savings', 'flex_boost_savings'];

  const fields = [
    { name: 'aws_organization_friendly_name', encode: true },
    { name: 'management_aws_account_number' },
    { name: 'effective_savings_rate' },
    { name: 'baseline_effective_savings_rate' },
    ...organizationSummaryResellerColumns(isReseller),
    { name: 'compute_usage', hasNormalized: true },
    { name: 'compute_usage_delta', isDelta: true },
    { name: 'compute_usage_delta_percentage', isDelta: true },
    { name: 'compute_spend', hasNormalized: true },
    { name: 'gross_savings', hasNormalized: true },
    // Unbilled Savings only apply to a handful of customers, so only include the column if there are unbilled savings
    hasUnbilledSavings && { name: 'unbilled_savings', hasNormalized: true },
    ...grossSavingsCategoryFields.map(field => ({ name: field, hasNormalized: true })),
    { name: 'savings_share', hasNormalized: true, hideFromDemo: true },
    { name: 'net_savings', hasNormalized: true, hideFromDemo: true },
    { name: 'net_savings_delta', isDelta: true, hideFromDemo: true },
    { name: 'net_savings_delta_percentage', isDelta: true, hideFromDemo: true },
    { name: 'incremental_savings', hasNormalized: true, hideFromDemo: true },
    { name: 'lifetime_savings' },
    { name: 'incremental_lifetime_savings' },
  ];

  return fields
    .filter(field => !!field)
    .map(field => {
      if (isDemo && field.hideFromDemo) return null;

      // Delta fields are only applicable when showing normalized values, so skip them when showing "actual" values
      if (!showNormalized && field.isDelta) return null;

      const fieldName = showNormalized && field.hasNormalized ? `${field.name}_normalized` : field.name;

      const value = organizationSummary[fieldName];
      return field.encode ? encode(value) : value;
    })
    .join(',');
};

const organizationSummaryLines = (
  isReseller,
  variant,
  hasUnbilledSavings,
  showNormalized,
  isDemo,
  organizationSummaries
) => {
  return _(organizationSummaries).map(organizationSummary => {
    return organizationSummaryLine(
      isReseller,
      variant,
      hasUnbilledSavings,
      showNormalized,
      isDemo,
      organizationSummary
    );
  });
};

export default {
  saveFile,
  showbackRows,
  showbackFileName,
  organizationSummaryFileName,
  organizationSummaryRows,
};
