<script>
import _ from 'lodash';
import { mapState, mapActions } from 'pinia';
import { mapActions as vuexMapActions, mapState as vuexMapState, mapGetters as vuexMapGetters } from 'vuex';

import { useAzureStore } from '@azure/state';
import nav from '@console/state/vuex/modules/nav';
import { useAuthStore } from '@shared/state/auth.store';
import { useUsersStore } from '@shared/state/users.store';

import LoadingPage from '@shared/components/LoadingPage.vue';

export default {
  components: {
    LoadingPage,
  },
  data() {
    return {
      switchToCompanyId: null,
      context: { cloud: null, id: null },
    };
  },
  computed: {
    ...mapState(useAuthStore, ['hasRoles', 'isGranularUser']),
    ...mapState(useAzureStore, ['billingScopes', 'isBillingScopeActive']),
    ...vuexMapGetters('aws', ['isNewOrganization', 'selectedOrganizationHasOnboarded']),
    ...vuexMapGetters('customer', ['isSettingEnabled', 'isSubscribed']),
    ...vuexMapGetters('gcp', ['billingAccounts', 'isBillingAccountOnboarded']),
    ...vuexMapGetters('nav', ['isAwsContext', 'isAzureContext', 'isGcpContext']),
    ...vuexMapState('aws', ['awsOrganizations']),
    ...vuexMapState('nav', { navContext: 'context' }),
    ...vuexMapState('customer', ['selectedCompanyId']),
    canSeeAwsDashboard() {
      return this.isSubscribed && this.selectedOrganizationHasOnboarded;
    },
    hasSelectableContext() {
      return this.isAwsContext || this.isAzureContext || this.isGcpContext;
    },
  },
  async mounted() {
    this.switchToCompanyId = this.$route.query.switchToCompanyId;
    this.context = {
      cloud: this.$route.query.contextCloud,
      id: this.$route.query.contextId,
    };

    try {
      await this.initialize();
      await this.establishContextForApp();
    } catch (e) {
      await this.$router.push({ name: 'error' });
    }
  },
  methods: {
    ...vuexMapActions('aws', {
      loadOrganizations: 'loadOrganizations',
      switchOrganization: 'switchOrganization',
      reset: 'resetAwsOrganization',
    }),
    ...vuexMapActions('customer', ['initializeCustomer', 'switchCompany']),
    ...vuexMapActions('gcp', ['loadAllBillingAccounts']),
    ...vuexMapActions('nav', ['switch']),
    ...mapActions(useAzureStore, ['loadBillingScopes']),
    async initialize() {
      if (this.hasRoles) {
        await this.initializeCustomer();
        await this.loadOrganizations();
        await this.loadAllBillingAccounts();
        await this.loadBillingScopes();
      }
    },
    async redirectToLanding() {
      const service = this.$route.query.service;
      if (this.isAwsContext && this.canSeeAwsDashboard) {
        if (service === 'elasticache') {
          await this.$router.replace({ path: '/aws/elasticache/savings/' });
        } else if (service === 'memorydb') {
          await this.$router.replace({ path: '/aws/memorydb/savings/' });
        } else if (service === 'opensearch') {
          await this.$router.replace({ path: '/aws/opensearch/savings/' });
        } else if (service === 'rds') {
          await this.$router.replace({ path: '/aws/rds/savings/' });
        } else if (service === 'redshift') {
          await this.$router.replace({ path: '/aws/redshift/savings/' });
        } else {
          await this.$router.replace({ name: 'aws_compute_savings' });
        }
      } else if (!this.hasSelectableContext) {
        await this.$router.replace({ name: 'start' });
      } else if (this.isGcpContext && this.isBillingAccountOnboarded(this.navContext.id) && this.isSubscribed) {
        await this.$router.replace({ name: 'google_cloud_compute_savings' });
      } else if (this.isGcpContext) {
        await this.$router.replace({ name: 'google_cloud_onboarding' });
      } else if (this.isAzureContext && this.isBillingScopeActive(this.navContext.id) && this.isSubscribed) {
        await this.$router.replace({ name: 'azure_compute_savings' });
      } else if (this.isAzureContext) {
        await this.$router.replace({ name: 'azure_onboarding' });
      } else {
        await this.$router.replace({ name: 'aws_onboarding' });
      }
    },
    async establishContextForApp() {
      if (!this.hasRoles) {
        await this.$router.replace({ name: 'company_add' });
      } else if (this.switchToCompanyId) {
        const userStore = useUsersStore();

        userStore.reset();
        await this.reset();
        await this.switchCompany(this.switchToCompanyId);
        await this.loadOrganizations(); // aws
        await this.loadAllBillingAccounts(); // gcp
        await this.loadBillingScopes(); // azure
        await this.redirectToLanding();
      } else if (_.get(this.$route, 'query', {}).awsOrganizationId) {
        await this.switchOrganization(this.$route.query.awsOrganizationId);
        await this.redirectToLanding();
      } else if (this.context.cloud && this.context.id) {
        await this.switch(this.context);
        await this.redirectToLanding();
      } else {
        await this.pickDefaultContext();
        await this.redirectToLanding();
      }
    },
    async pickDefaultContext() {
      /*
       * This function is reponsible for choosing the "default" context that a user
       * should see when they first log into the customer console. The default context
       * applies when a specific context was not specified during the login flow.
       *
       * When `initializeAwsOrganization` is called as part of loading this
       * component, it has the side effect of *always* selecting a nav context.
       * That nav context will be the Multi-Org Summary, a specific AWS organization,
       * or the NEW_ORGANIZATION_PLACEHOLDER.
       *
       * This worked well when we only supported AWS. However, it now has the side
       * effect of always forcing customers without AWS organizations to the AWS
       * onboarding screen. Instead, we would like to show them an existing GCP
       * billing account if they have one.
       */

      // If a "real" AWS context is already selected (aka, not the NEW_ORGANIZATION_PLACEHOLDER),
      // do nothing. There is already an appropriate context selected.
      if (this.isAwsContext && !this.isNewOrganization) {
        return;
      }

      // If a GCP context is already selected, do nothing. We never automatically choose a GCP
      // context as part of data loading, so there doesn't need to be a special case for handling
      // NEW_BILLING_ACCOUNT_PLACEHOLDER
      if (this.isGcpContext) {
        return;
      }

      // If an AWS Context or GCP Context is _not_ set, we need to establish context with an active AWS
      // Organization.
      const allAwsOrganizations = Object.values(this.awsOrganizations);
      const activeAwsOrganizations = allAwsOrganizations.filter(o => o.status === 'Active');
      if (activeAwsOrganizations.length > 1) {
        // Granular users dont have access to the multi-org summary page because
        // that is considered global data so just take them to their first aws org instead
        if (this.isGranularUser(this.selectedCompanyId)) {
          await this.switch({ cloud: nav.clouds.AWS, id: activeAwsOrganizations[0].id });
        } else {
          await this.switch({ cloud: nav.clouds.AWS, id: 'MULTI_ORG_SUMMARY' });
        }
        return;
      }
      if (activeAwsOrganizations.length === 1) {
        await this.switch({ cloud: nav.clouds.AWS, id: activeAwsOrganizations[0].id });
        return;
      }

      // If an AWS Context or GCP Context is _not_ set, and the customer has no active AWS Organizations
      // we need to establish context with an active billing account.
      const activeBillingAccount = this.billingAccounts?.find(ba => ba.status === 'Active');
      if (activeBillingAccount) {
        await this.switch({ cloud: nav.clouds.GCP, id: activeBillingAccount.id });
        return;
      }

      if (allAwsOrganizations.length > 0) {
        await this.switch({ cloud: nav.clouds.AWS, id: allAwsOrganizations[0].id });
        return;
      }

      if (this.billingAccounts?.length > 0) {
        await this.switch({ cloud: nav.clouds.GCP, id: this.billingAccounts[0].id });
        return;
      }

      var billingScopes = Object.values(this.billingScopes);
      if (billingScopes.length > 0) {
        await this.switch({ cloud: nav.clouds.AZURE, id: billingScopes[0].id });
      }

      // The user has no prior context and no active AWS Organizations _or_ Billing Accounts
      // No context can be set and user will be redirected to Start to establish context
    },
  },
};
</script>

<template>
  <LoadingPage />
</template>
