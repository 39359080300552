<script setup lang="ts">
import _ from 'lodash';
import { computed, defineProps, ref } from 'vue';

import ShowbackTableAggregateRow from '@aws/components/adm/showback/ShowbackTableAggregateRow.vue';
import ShowbackTableRow from '@aws/components/adm/showback/ShowbackTableRow.vue';
import ShowbackTableServiceRow from '@aws/components/adm/showback/ShowbackTableServiceRow.vue';

interface RowElement {
  service: string;
  cost: number;
  usage: number;
  savings_share: number;
}

const props = defineProps<{
  row: {
    friendly_name: string;
    rows: RowElement[];
  };
}>();

const priority = [
  'ProsperOps Savings Share',
  'ProsperOps RDS Savings Share',
  'ProsperOps ElastiCache Savings Share',
  'ProsperOps MemoryDB Savings Share',
  'ProsperOps OpenSearch Savings Share',
  'ProsperOps Redshift Savings Share',
];

const expanded = ref<boolean>(false);

const sortKeyLookup = computed(() =>
  _.reduce(priority, (acc: object, curr: string, i: number) => ({ ...acc, [curr]: i }), {})
);
const selectedRows = computed(() => {
  const defaultSortKey = _.size(sortKeyLookup);
  const sortKey = (key: string) => _.get(sortKeyLookup, key, defaultSortKey);
  const rowBuilder = (rows: RowElement[], key: string) => ({ key, rows, sort_key: sortKey(key) });
  const rows = _.get(props.row, 'rows', []);
  const unsorted = _.map(_.groupBy(rows, 'service'), rowBuilder);
  return _.orderBy(unsorted, ['sort_key'], ['asc']);
});
const expandSavingsShareRows = computed(() => props.row.rows.length > 1);
const getSingleRow = computed(() => props.row.rows[0]);
const onClick = () => {
  expanded.value = !expanded.value;
};
</script>

<template>
  <div>
    <div v-if="expandSavingsShareRows">
      <BaseButton variant="transparent" class="btn-no-focus-box-shadow w-100 p-0" @click="onClick">
        <ShowbackTableAggregateRow :rows="row.rows" :level="0" :expanded="expanded">
          <template v-slot:label>
            <div :title="row.friendly_name">
              <div class="text-truncate">{{ row.friendly_name }}</div>
            </div>
          </template>
        </ShowbackTableAggregateRow>
      </BaseButton>
      <div v-if="expanded">
        <ShowbackTableServiceRow v-for="curr in selectedRows" :key="curr.key" :rows="curr.rows">
          <template v-slot:label>
            <div class="text-truncate">
              {{ curr.key }}
            </div>
          </template>
        </ShowbackTableServiceRow>
      </div>
    </div>
    <div v-else>
      <ShowbackTableRow class="resourceRow" :row="getSingleRow" :level="0">
        <template v-slot:label>
          <div>
            {{ getSingleRow.service }}
          </div>
        </template>
      </ShowbackTableRow>
    </div>
  </div>
</template>
