<script setup lang="ts">
import type { AwsAdmOfferingCode, BillingOfferingVariant } from '@console/services/api.models';
import type {
  ComputeBaseFlexPortfolioAllocation,
  ComputeInheritedBaseSmartPortfolioAllocation,
  NonComputePortfolioAllocation,
  PortfolioAllocation,
} from '@console/services/aws/savings.models';
import type Highcharts from 'highcharts';
import type { PropType } from 'vue';

import { ref, computed } from 'vue';

import NumberHelpers from '@shared/utilities/number_helpers';
import { isTruthy } from '@shared/utilities/typescript_helpers';

import DonutChart from '@console/components/charts/DonutChart.vue';
import StackedChartLegend from '@console/components/charts/StackedChartLegend.vue';
import Percentage from '@shared/components/Percentage.vue';
import TextTooltip from '@shared/design/TextTooltip.vue';

type SeriesColor = { background: string; label: string };
type SeriesColors = Partial<Record<keyof PortfolioAllocation, SeriesColor>>;

const withLightLabel = (background: string): SeriesColor => ({ background, label: '#ffffff' });
const withDarkLabel = (background: string): SeriesColor => ({ background, label: '#212529' });

const seriesColors: {
  compute: Partial<Record<BillingOfferingVariant, SeriesColors>>;
  nonCompute: SeriesColors;
} = {
  compute: {
    AwsComputeBaseFlex: {
      base_one_year_percentage: withLightLabel('#a7a3ff'),
      base_three_year_percentage: withLightLabel('#5c54ff'),
      flex_one_year_percentage: withDarkLabel('#8fffdf'),
      flex_three_year_percentage: withLightLabel('#00c58c'),
      flex_boost_one_year_percentage: withDarkLabel('#feebbf'),
      flex_boost_three_year_percentage: withDarkLabel('#fcbe2c'),
      unbilled_one_year_percentage: withDarkLabel('#dee2e6'),
      unbilled_three_year_percentage: withDarkLabel('#adb5bd'),
      spot_percentage: withDarkLabel('#EBEAFF'),
      on_demand_percentage: withLightLabel('#fc5454'),
      capacity_block_reservation_percentage: withDarkLabel('#FECBCB'),
    } as Record<keyof ComputeBaseFlexPortfolioAllocation, SeriesColor>,
    AwsComputeInheritedBaseSmart: {
      base_one_year_percentage: withLightLabel('#a7a3ff'),
      base_three_year_percentage: withLightLabel('#5c54ff'),
      smart_one_year_percentage: withDarkLabel('#8fffdf'),
      smart_three_year_percentage: withLightLabel('#00c58c'),
      inherited_one_year_percentage: withDarkLabel('#feebbf'),
      inherited_three_year_percentage: withDarkLabel('#fcbe2c'),
      unbilled_one_year_percentage: withDarkLabel('#dee2e6'),
      unbilled_three_year_percentage: withDarkLabel('#adb5bd'),
      spot_percentage: withDarkLabel('#EBEAFF'),
      on_demand_percentage: withLightLabel('#fc5454'),
      capacity_block_reservation_percentage: withDarkLabel('#FECBCB'),
    } as Record<keyof ComputeInheritedBaseSmartPortfolioAllocation, SeriesColor>,
  },
  nonCompute: {
    smart_one_year_percentage: withDarkLabel('#8fffdf'),
    smart_three_year_percentage: withLightLabel('#00c58c'),
    inherited_one_year_percentage: withLightLabel('#a7a3ff'),
    inherited_three_year_percentage: withLightLabel('#5c54ff'),
    on_demand_percentage: withLightLabel('#fc5454'),
  } as Record<keyof NonComputePortfolioAllocation, SeriesColor>,
};

const props = defineProps({
  portfolioAllocation: {
    type: Object as PropType<PortfolioAllocation>,
    required: true,
  },
  dashboardVariant: {
    type: String as PropType<BillingOfferingVariant>,
    required: false,
    default: undefined,
  },
  service: {
    type: String as PropType<AwsAdmOfferingCode>,
    required: true,
    default: 'compute',
  },
  allDiscounts: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const isProductCompute = computed(() => props.service === 'compute');

interface CommitmentSeriesDefs {
  field: 'unbilled' | 'base' | 'flex' | 'flex_boost' | 'inherited' | 'smart';
  label: string;
}
const commitmentSeriesDefs: CommitmentSeriesDefs[] = [
  { field: 'base', label: 'Base' },
  { field: 'flex', label: 'Flex' },
  { field: 'flex_boost', label: 'Flex Boost' },
  { field: 'smart', label: 'Smart' },
  // Inherited & Unbilled are at the end because they aren't managed and have lower priority
  { field: 'inherited', label: 'Inherited' },
];

const getDiscountLabel = (key: keyof PortfolioAllocation) => {
  const discount = props.portfolioAllocation[key];
  if (!discount) return 'receiving no discount';
  return `receiving ${NumberHelpers.formatNumber(discount, 1)}% discount`;
};

interface SeriesDef {
  name: string;
  field: keyof PortfolioAllocation;
}
const seriesDefs: SeriesDef[] = [
  // Translate the commitment series to their 1 and 3 year counterparts
  ...commitmentSeriesDefs.flatMap(s => [
    {
      name: `${s.label}: 1 Year (${getDiscountLabel(`${s.field}_one_year_discount_percentage`)})`,
      field: `${s.field}_one_year_percentage`,
    } as SeriesDef,
    {
      name: `${s.label}: 3 Year (${getDiscountLabel(`${s.field}_three_year_discount_percentage`)})`,
      field: `${s.field}_three_year_percentage`,
    } as SeriesDef,
  ]),
  // Add in On-demand, because it isn't broken out by year
  props.allDiscounts && { name: `Spot (${getDiscountLabel('spot_discount_percentage')})`, field: 'spot_percentage' },
  {
    name: `Unbilled: 1 Year (${getDiscountLabel(`unbilled_one_year_discount_percentage`)})`,
    field: `unbilled_one_year_percentage`,
  },
  {
    name: `Unbilled: 3 Year (${getDiscountLabel(`unbilled_three_year_discount_percentage`)})`,
    field: `unbilled_three_year_percentage`,
  },
  {
    name: `On-Demand (${getDiscountLabel('on_demand_discount_percentage')})`,
    field: 'on_demand_percentage',
  },
  props.allDiscounts && {
    name: `Capacity Block (${getDiscountLabel('capacity_block_reservation_discount_percentage')})`,
    field: 'capacity_block_reservation_percentage',
  },
].filter(isTruthy) as SeriesDef[];

interface PieSliceOptions extends Highcharts.PointOptionsObject {
  legendOrder: number;
}
const data = ref(
  seriesDefs
    .map((s, index) => {
      const colors = getColorsBySeries()[s.field];
      const y = props.portfolioAllocation[s.field];

      const options: PieSliceOptions = {
        name: s.name,
        label: s.name,
        y: y,
        color: colors?.background,
        legendOrder: index,
        dataLabels: {
          enabled: (y ?? 0) >= 5,
          format: '{point.percentage:.1f}%',
          distance: '-25%', // innerSize is 50%, so this is half that
          style: {
            color: colors?.label,
            textOutline: '0px',
            fontWeight: '400',
          },
        },
      };
      return options;
    })
    // Don't include any slices without a color (i.e. series belongs to another service, this shouldn't happen)
    .filter(s => !!s.color)
    // Don't include any slices that don't have a value
    .filter(s => (s.y ?? 0) > 0)
);

const discountFactors = computed(() => {
  if (isProductCompute.value) {
    return 'compute product, 1 or 3 year term, prepayment type, platform, region, etc';
  } else if (props.service === 'rds') {
    return '1 or 3 year term, prepayment type, platform, region, etc';
  } else if (props.service === 'elasti_cache') {
    return '1 or 3 year term, prepayment type, engine, region, etc';
  } else {
    return '1 or 3 year term, prepayment type, region, etc';
  }
});

function getColorsBySeries() {
  return isProductCompute.value ? seriesColors.compute[props.dashboardVariant!] ?? {} : seriesColors.nonCompute;
}
</script>

<template>
  <div class="portfolioAllocation row">
    <div>
      <DonutChart name="Portfolio Distribution" :data="data" />
      <div class="blendedDiscount">
        <div>
          <TextTooltip placement="bottom" class="mr-2">
            Blended Discount:
            <template #tooltip>
              <strong>Blended Discount</strong> measures the overall discount received from the various savings
              instruments in your portfolio. This is influenced by multiple factors including {{ discountFactors }}.
            </template>
          </TextTooltip>
          <strong>
            <Percentage :value="portfolioAllocation.effective_discount_percentage" />
          </strong>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <StackedChartLegend :value="data" class="chartLegend" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.portfolioAllocation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.blendedDiscount {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1.4rem;
  font-size: 1.2rem;
  line-height: 1.4;
}

.chartLegend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    flex-direction: column;
  }
}
</style>
