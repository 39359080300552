<script lang="ts" setup>
import type {
  AwsComputeSavingsDashboard,
  AwsOrganizationComputeSavingsDashboard,
} from '@console/services/aws/savings.models';

import { computed, ref } from 'vue';

import SavingsMetric from './SavingsMetric.vue';
import CyclicalWaveIcon from '@shared/design/icons/CyclicalWaveIcon.vue';
import Modal from '@shared/design/modals/Modal.vue';

const props = defineProps<{
  dashboard: AwsComputeSavingsDashboard;
  useSmallerText: boolean;
}>();

const showModal = ref(false);

const utilizationPercentage = computed(() => props.dashboard?.key_metrics?.savings_plan_utilization_percentage ?? 0);
const showCyclicalityDetected = computed(
  () => (props.dashboard as AwsOrganizationComputeSavingsDashboard)?.global_cyclicality_detected ?? false
);
</script>

<template>
  <SavingsMetric
    name="savings_plan_utilization_percentage"
    variant="secondary"
    :use-smaller-text="useSmallerText"
    :value="utilizationPercentage"
  >
    <template v-if="showCyclicalityDetected" v-slot:value-icon>
      <a href="#" class="wave" @click.prevent="showModal = true"><CyclicalWaveIcon /></a>
      <Modal v-model="showModal" size="md" title="Global Cyclical Usage Detected">
        <div class="pb-3">
          <span>
            Our platform has detected global cyclical usage patterns and is using advanced strategies to maximize your
            savings. These techniques may lower your Savings Plan utilization rate but improve your Effective Savings
            Rate.
          </span>
          <a
            href="https://www.prosperops.com/blog/how-to-maximize-cloud-savings-on-cyclical-workloads-using-commitment-based-discounts/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-nowrap"
          >
            Learn more
          </a>
        </div>
      </Modal>
    </template>
  </SavingsMetric>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';

.wave {
  margin-left: 0.25rem;
  line-height: 1;
  color: map-get($theme-colors, 'secondary');

  &:hover {
    color: darken(map-get($theme-colors, 'secondary'), 10%);
  }

  > svg {
    height: 18px;
  }
}
</style>
