import { getServiceValue } from "@shared/utilities/service_helpers";

export type GoogleCloudOfferingCode = 'cloud_sql';

const displayNames = {
  cloud_sql: 'Cloud SQL',
};

export default {
    getDisplayName: (service: GoogleCloudOfferingCode) => getServiceValue(service, 'Display Name', displayNames),
};
