<script lang="ts" setup>
import type { User } from '@shared/state/users.store';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useVuexStore } from '@console/state/vuex/store';
import useModals from '@shared/design/modals/useModals';
import { RoleTypes } from '@shared/services/customer.models';
import { useAuthStore } from '@shared/state/auth.store';

import UserActionsDropdown from '@console/components/users/UserActionsDropdown.vue';
import UserImage from '@console/components/users/UserImage.vue';
import UserName from '@console/components/users/UserName.vue';
import UserStatusBadge from '@console/components/users/UserStatusBadge.vue';

const props = defineProps<{
  users: User[];
}>();
const emits = defineEmits<{
  (e: 'resend-verification', user: User): void;
  (e: 'user-deleted', user: User): void;
}>();

const authStore = useAuthStore();
const vuexStore = useVuexStore();

const companyName = computed(() => vuexStore.getters['customer/selectedCompany'].company_name);

function canSubmitActions(user: User) {
  // A user cannot submit actions on themselves
  return user.email_address !== authStore.email;
}

function canRemove(user: User) {
  if (user.user_role === RoleTypes.Owner) {
    const owners = props.users.filter(u => u.user_role === RoleTypes.Owner);
    return owners.length > 1;
  }
  return true;
}

const router = useRouter();
async function updateRole(user: User) {
  await router.push(`/user_management/${encodeURIComponent(user.email_address)}`);
}

async function resendVerification(user: User) {
  emits('resend-verification', user);
}

const modals = useModals();
async function removeUser(user: User) {
  const message = `Remove ${user.email_address} from ${companyName.value}?`;
  const result = await modals.showConfirm({
    title: 'Please Confirm',
    message,
    okVariant: 'danger',
    okTitle: 'Remove',
    cancelTitle: 'Cancel',
    cancelVariant: 'outline-info',
  });

  if (result) {
    emits('user-deleted', user);
  }
}
</script>

<template>
  <ul class="userList list-group rounded-sm">
    <li
      v-for="user in users"
      :key="user.email_address"
      :data-user-email-address="user.email_address"
      class="list-group-item pr-0"
    >
      <router-link
        :to="`/user_management/${encodeURIComponent(user.email_address)}`"
        class="w-100 text-decoration-none text-body"
      >
        <div class="userRow">
          <div class="userInfo">
            <div class="userImage">
              <UserImage :user="user" />
            </div>
            <UserName :user="user" />
          </div>
          <div class="userAttributes">
            <div class="userRole">
              <div>{{ user.user_role === 'Granular' ? 'Custom' : user.user_role }}</div>
            </div>
            <div class="d-flex justify-content-center align-items-center userStatus">
              <UserStatusBadge :user="user" />
            </div>
            <div class="d-flex justify-content-end align-items-center userActions pr-3">
              <UserActionsDropdown
                v-if="canSubmitActions(user)"
                :user="user"
                :can-remove="canRemove(user)"
                @update-role="updateRole"
                @resend-verification="resendVerification"
                @remove-user="removeUser"
              />
            </div>
          </div>
        </div>
      </router-link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.userList {
  padding: 0;
  padding-top: 0.5rem;
  list-style-type: none;
}

.userList > li {
  display: flex;
  min-height: 68px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}

.userRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.userImage {
  flex-shrink: 0;
  width: 50px;

  @include media-breakpoint-up(lg) {
    width: 60px;
  }
}

.userInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    padding-bottom: 0;
  }
}

.userAttributes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    padding-bottom: 0;
  }
}

.userAttributes > div {
  display: flex;
  align-items: center;
}

.userStatus {
  flex-shrink: 0;
}

.userActions {
  flex-shrink: 0;
  width: 120px;
}

.userRole {
  flex-shrink: 0;
  width: 100px;
  padding-left: 1rem;
}
</style>
