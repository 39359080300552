<script lang="ts" setup>
import _ from 'lodash';
import { computed, ref, watch } from 'vue';

import Dropdown from './dropdowns/Dropdown.vue';
import DropdownContent from './dropdowns/DropdownContent.vue';
import DropdownDivider from './dropdowns/DropdownDivider.vue';
import DropdownItem from './dropdowns/DropdownItem.vue';

export interface Timeframe {
  key: string;
  friendly_name: string;
}
const props = defineProps<{
  selected: Timeframe;
  timeframes: Timeframe[];
}>();

const emits = defineEmits<{
  (e: 'change', newTimeframe: Timeframe): void;
}>();

const current = ref<Timeframe | null>(null);
const startIndex = ref(0);

const pageSize = 6;

const currentPage = computed(() => props.timeframes.slice(startIndex.value, startIndex.value + pageSize));
const canNextPage = computed(() => startIndex.value + pageSize < props.timeframes.length);
const canPrevPage = computed(() => startIndex.value - pageSize >= 0);
const showPagination = computed(() => props.timeframes.length > pageSize);
const spacers = computed(() => (showPagination.value ? _.range(0, currentPage.value.length - pageSize) : []));

// When either the selected timeframe or the timeframes change, recalculate current and startIndex
watch(props, () => setStartIndex(), { immediate: true, deep: true });

function setStartIndex() {
  const index = props.timeframes.findIndex(t => t.key === props.selected.key);
  current.value = props.timeframes[index];
  startIndex.value = Math.floor(index / pageSize) * pageSize;
}

function change(newKey: string) {
  if (newKey !== current.value?.key) {
    const found = props.timeframes.find(t => t.key === newKey)!;
    emits('change', found);
  }
}

function prevPage() {
  startIndex.value = startIndex.value - pageSize;
}
function nextPage() {
  startIndex.value = startIndex.value + pageSize;
}
</script>

<template>
  <Dropdown
    variant="primary"
    dropdown-placement="bottom-end"
    dropdown-class="rounded-0 mt-1 py-1 timeframeMenu"
    toggle-class="rounded-sm"
  >
    <template #toggleContent>
      <div v-if="current">
        {{ current.friendly_name }}
      </div>
    </template>
    <DropdownItem
      v-for="timeframe in currentPage"
      :key="timeframe.key"
      class="text-right"
      @click="change(timeframe.key)"
    >
      <div class="timeframeItem" :class="{ active: timeframe.key === selected.key }">
        {{ timeframe.friendly_name }}
      </div>
    </DropdownItem>

    <!-- Insert spacers on the earliest page so the menu stays the same height when paging -->
    <DropdownItem v-for="spacer in spacers" :key="'spacer-' + spacer" disabled>
      <div class="timeframeItem">&nbsp;</div>
    </DropdownItem>

    <DropdownDivider v-if="showPagination" />
    <DropdownContent v-if="showPagination" class="paginator">
      <div class="d-flex align-items-center">
        <div v-if="canNextPage" class="timeframePaginator">
          <BaseButton
            variant="transparent"
            class="p-0 btn-no-focus-box-shadow timeframePaginatorButton"
            @click="nextPage"
          >
            <small class="text-muted"><BaseIcon name="arrow-left" /></small>
          </BaseButton>
        </div>

        <!-- Without this spacer, the earilest page's height would increase by a couple pixels -->
        <div class="flex-grow-1">&nbsp;</div>

        <div v-if="canPrevPage" class="timeframePaginator">
          <BaseButton
            variant="transparent"
            class="p-0 btn-no-focus-box-shadow timeframePaginatorButton"
            @click="prevPage"
          >
            <small class="text-muted"><BaseIcon name="arrow-right" /></small>
          </BaseButton>
        </div>
      </div>
    </DropdownContent>
  </Dropdown>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

:deep(.timeframeMenu) {
  width: 170px;
}

.timeframePaginator {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25px;
}

.timeframePaginatorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: $gray-600;
  background-color: $gray-300;
  border-radius: 50%;
}

.timeframeItem {
  display: inline-block;

  // When a timeframe item is active, it gets a bottom border, to ensure they all have the same height, add vertical
  // padding for the same height as the border
  padding: 1.5px 0;

  &.active {
    // When active, add the border and remove the padding that accounts for the missing border's height
    padding: 0;
    border-bottom: 3px solid map-get($theme-colors, 'primary');
  }
}
</style>
