<script>
import Tooltip from '@shared/design/Tooltip.vue';

export default {
  components: {
    Tooltip,
  },
};
</script>

<template>
  <div class="showbackHeader pt-1">
    <div></div>
    <div>
      <Tooltip target="showback_header_original_cost">
        <div>
          <span class="tooltip-target">Original</span><br />
          <span class="tooltip-target">Cost</span><br />
          (A)
        </div>
        <template #tooltip>
          AWS compute costs as shown on the invoice (i.e., with improperly allocated savings)
        </template>
      </Tooltip>
    </div>
    <div class="mr-0">
      <Tooltip>
        <div>
          <span class="tooltip-target">Undiscounted</span><br />
          <span class="tooltip-target">Usage</span><br />
          (B)
        </div>
        <template #tooltip>What would have been paid to AWS if there were no discounts</template>
      </Tooltip>
    </div>
    <div class="ml-0 mr-0">
      <Tooltip>
        <div>
          <span class="tooltip-target">Reallocated</span><br />
          <span class="tooltip-target">Savings</span><br />
          (C)
        </div>
        <template #tooltip>The portion of total compute savings generated properly allocated back</template>
      </Tooltip>
    </div>
    <div class="ml-0 mr-0">
      <Tooltip>
        <div>
          <span class="tooltip-target">Adjusted</span><br />
          <span class="tooltip-target">Cost</span><br />
          (D = B - C)
        </div>
        <template #tooltip>AWS compute costs with properly allocated savings</template>
      </Tooltip>
    </div>
    <div>
      <Tooltip>
        <div>
          <span class="tooltip-target">Net</span><br />
          <span class="tooltip-target">Adjustment</span><br />
          (E = D - A)
        </div>
        <template #tooltip>
          The amount of incremental compute cost to add to or remove from the AWS invoice value
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.showbackHeader {
  display: flex;
  justify-content: space-between;

  > div:nth-child(2),
  > div:nth-child(3),
  > div:nth-child(6) {
    margin-left: 6px;

    @media (min-width: 1660px) {
      margin-left: 12px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 30px;
    padding: 0.6rem 0.4rem;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    background-color: map-get($theme-colors, 'info');

    @media (min-width: 1260px) {
      min-width: 40px;
      font-size: 0.85rem;
    }

    @media (min-width: 1660px) {
      min-width: 160px;
      padding: 1rem 0.8rem;
      font-size: 1rem;
    }
  }

  > div:first-child {
    flex-grow: 1;
    justify-content: flex-start;
    min-width: 220px;

    @media (min-width: 1260px) {
      min-width: 280px;
    }

    @media (min-width: 1660px) {
      min-width: 400px;
    }
  }
}
</style>
