import type { RouteRecordRaw } from 'vue-router';

import admRoutes from './adm/index';
import armRoutes from './arm';
import { hasSingleOrgSelected, notNewOrganization, noActiveSubscriptionOrNotConfiguredOrg } from './requirements';
import {
  requires,
  hasRoles,
  selectedCompany,
  atLeastRole,
  activeSubscription,
  notInDemo,
  isFeatureEnabled,
} from '@console/router/requirements';
import { PermissionLevel } from '@shared/state/auth.store';

import Account from '@aws/views/Account.vue';
import Accounts from '@aws/views/Accounts.vue';
import Onboarding from '@aws/views/Onboarding.vue';
import Settings from '@aws/views/Settings.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/aws/onboarding',
    name: 'aws_onboarding',
    component: Onboarding,
    beforeEnter: requires(
      notInDemo,
      hasRoles,
      selectedCompany,
      atLeastRole(PermissionLevel.Viewer),
      noActiveSubscriptionOrNotConfiguredOrg
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/accounts',
    name: 'aws_accounts',
    component: Accounts,
    beforeEnter: requires(
      notInDemo,
      hasRoles,
      selectedCompany,
      activeSubscription,
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },
  {
    path: '/aws/settings',
    name: 'aws_settings',
    component: Settings,
    beforeEnter: requires(
      hasRoles,
      selectedCompany,
      atLeastRole(PermissionLevel.Editor),
      activeSubscription,
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      useNewLayout: true,
      initModules: true,
    },
  },
  {
    path: '/aws/accounts/:awsAccountId',
    name: 'aws_configure_account',
    component: Account,
    beforeEnter: requires(
      notInDemo,
      hasRoles,
      selectedCompany,
      atLeastRole(PermissionLevel.Editor),
      notNewOrganization,
      hasSingleOrgSelected
    ),
    meta: {
      initModules: true,
    },
  },

  // Add routes for other aws features here
  ...admRoutes,
  ...armRoutes,
];

export default routes;
