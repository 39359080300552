<script lang="ts" setup>
import type { Moment } from 'moment';

import moment from 'moment';
import { ref, computed } from 'vue';

import CostBreakdownLink from '@gcp/components/savings/CostBreakdownLink.vue';
import Toggle from '@shared/components/Toggle.vue';
import Modal from '@shared/design/modals/Modal.vue';

const model = defineModel<boolean>({ required: true });
const props = defineProps({
  billingAccountId: {
    type: String,
    required: true,
  },
  month: {
    type: String,
    required: true,
  },
  summary: {
    type: Object,
    required: true,
  },
  hasContractPricing: {
    type: Boolean,
    required: true,
  },
  isDemo: {
    type: Boolean,
    required: true,
  },
});

const usd = 'USD';
const useBillingAccountCurrency = ref(false);

const format = (amount: number) => {
  // We pass in each savings amount as a negative amount. However, IEEE 754 floating
  // point numbers support the concept of both positive and negative zero. This causes
  // negative zero amounts that are passed in to be rendered as "($0.00)" instead of
  // just "$0.00". This conditional forces all zero values to be displayed as "$0.00".
  if (amount === 0) {
    amount = 0;
  }

  var currency = useBillingAccountCurrency.value ? props.summary?.currency_code : usd;
  var rate = useBillingAccountCurrency.value ? props.summary?.currency_conversion_rate : 1.0;
  var formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency });
  return formatter.format(amount * rate);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const param = (key: string, value: any) => {
  const values = [].concat(value);
  const escaped = values.map(v => encodeURIComponent(v));
  return `${key}=${escaped.join(',')};`;
};

const formatMomentDate = (date: Moment) => date.format('YYYY-MM-DD');

const product = 'services/6F81-5844-456A';
const skus = props.summary.skus.map((sku: string) => `${product}/skus/${sku}`);
const to = moment.utc(props.month);
const from = moment.utc(to).startOf('month');
const friendlyDate = to.format('MMMM YYYY');

const url = computed(() => {
  let url = `https://console.cloud.google.com/billing/${props.billingAccountId}/reports/cost-breakdown;`;
  url += param('dateType', 'USAGE_DATE');
  url += param('timeRange', 'CUSTOM_RANGE');
  url += param('from', formatMomentDate(from));
  url += param('to', formatMomentDate(to));
  url += param('products', product);
  url += param('skus', skus);
  return url;
});

const showLink = computed(() => url.value.length <= 1550);
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <Modal v-model="model" size="lg" title="Compute Cost Breakdown in Google Cloud Console">
    <p>
      ProsperOps considers Google Cloud systems authoritative for cost and usage data. The Google Cloud Console breaks
      down Compute spend based on cost while ProsperOps presents data in a savings orientation. The corresponding cost
      view is shown here which you can validate with Google Cloud<span v-if="showLink">
        by clicking the button below</span
      >.
    </p>
    <div class="d-flex justify-content-end mb-3">
      <Toggle
        v-if="props.summary?.currency_code !== usd"
        :enabled="useBillingAccountCurrency"
        :on="props.summary?.currency_code"
        :off="usd"
        @change="enabled => (useBillingAccountCurrency = enabled)"
      />
    </div>
    <table class="table costBreakdown mb-3">
      <thead>
        <tr>
          <td>
            <strong>{{ friendlyDate }}</strong> (Actual)
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ props.hasContractPricing ? 'List cost' : 'Usage cost' }}
            <br />
            <small class="text-muted">on-demand usage plus cost of commitments</small>
          </td>
          <td>{{ format(summary.list_cost) }}</td>
        </tr>
        <tr v-if="hasContractPricing">
          <td>
            Negotiated savings<br />
            <small class="text-muted">savings from contract pricing</small>
          </td>
          <td>{{ format(-summary.negotiated_savings) }}</td>
        </tr>
        <tr>
          <td>
            Committed use discounts (resource based)<br />
            <small class="text-muted">on-demand usage offset by resource-based CUDs</small>
          </td>
          <td>{{ format(-summary.committed_use_discount_savings) }}</td>
        </tr>
        <tr>
          <td>
            Committed use discounts (spend based)<br />
            <small class="text-muted">on-demand usage offset by spend-based CUDs</small>
          </td>
          <td>
            {{ format(-summary.flexible_committed_use_discount_savings) }}
          </td>
        </tr>
        <tr>
          <td>
            Sustained use discounts<br />
            <small class="text-muted">on-demand usage offset by SUDs</small>
          </td>
          <td>{{ format(-summary.sustained_use_discount_savings) }}</td>
        </tr>
        <tr>
          <td>
            Other credits<br />
            <small class="text-muted">additional compute discounts</small>
          </td>
          <td>{{ format(-summary.other_credits) }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            Compute spend<br />
            <small class="text-muted">this matches the ProsperOps savings breakdown view (in actual dollars)</small>
          </td>
          <td>{{ format(summary.total_spend) }}</td>
        </tr>
      </tfoot>
    </table>
    <div v-if="showLink" class="d-flex justify-content-end">
      <CostBreakdownLink :url="url" :disabled="isDemo" />
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.costBreakdown {
  thead td {
    height: inherit;
    padding-top: 0;
    padding-bottom: 1em;
    border: none;
  }

  td {
    height: 4.5rem;
    vertical-align: middle;
  }

  td:nth-child(2) {
    text-align: right;
  }

  tfoot {
    font-weight: 500;
    background-color: $gray-50;
  }
}
</style>
