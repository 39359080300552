<script lang="ts" setup>
import { useHead } from '@unhead/vue';
import { computed, ref, onMounted } from 'vue';

import { useAzureStore } from '@azure/state';
import { useVuexStore } from '@console/state/vuex/store';

import RequiresRole from '@console/components/RequiresRole.vue';
import SettingRow from '@console/components/settings/SettingRow.vue';
import CalendlyButton from '@console/components/widgets/CalendlyButton.vue';
import PageLayout from '@console/PageLayout.vue';
import BaseTarget from '@gcp/components/settings/BaseTarget.vue';
import PageHeader from '@shared/design/PageHeader.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import StatusPill from '@shared/design/StatusPill.vue';

const azureState = useAzureStore();
const store = useVuexStore();
const settings = computed(() => azureState.getGlobalSettingsById(context.value.id));
const loading = ref(true);
const context = computed(() => store.getters['nav/context']);
const isBaseTargetAutoIncreaseEnabled = computed(() =>
  settings.value.base_target_auto_increase_percentage
    ? settings.value.base_target_auto_increase_percentage !== '0'
    : false
);

onMounted(async () => {
  loading.value = true;
  await azureState.loadSelectedBillingScope();
  loading.value = false;
});
useHead({
  title: 'Azure Compute Settings',
});
</script>

<template>
  <PageLayout :loading="loading">
    <template #default>
      <div v-if="settings" class="settingsContainer">
        <PageHeader>
          <h1>Compute Settings</h1>
        </PageHeader>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Status" rounded new-header class="settingsConstrainedWidth">
              <SettingRow class="settingRow">
                <template v-slot:label> Managing Commitment</template>
                <template v-slot:description>
                  Manage a portfolio of Base and Smart commitments. The Smart layer utilizes an Adaptive Laddering
                  strategy which dynamically adjusts based on usage changes.
                </template>
                <template v-slot:value>
                  <StatusPill :enabled="settings.commitment_management_enabled === true">
                    <span v-if="settings.commitment_management_enabled">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Base" rounded new-header class="settingsConstrainedWidth">
              <template v-slot:utility>
                <LastUpdated :value="settings.base_last_updated" />
              </template>
              <SettingRow class="settingRow">
                <template v-slot:label> Base Target </template>
                <template v-slot:description>
                  Desired amount of Compute Savings Plan spend coverage to maintain (in on-demand equivalent dollars for
                  a 30 day month).
                </template>
                <template v-slot:value>
                  <BaseTarget :settings="settings" />
                </template>
              </SettingRow>
              <div class="mt-4">
                <span class="text-uppercase font-weight-bold">Advanced</span>
              </div>
              <SettingRow class="settingRow">
                <template v-slot:label> Base Target Auto Increase </template>
                <template v-slot:description>
                  When the amount of compute usage that can
                  <strong>only</strong> be covered by Compute Savings Plans increases above the Base Target,
                  automatically increase the Base Target to cover as long as the new target amount is less than
                  <strong
                    >{{ isBaseTargetAutoIncreaseEnabled ? settings.base_target_auto_increase_percentage : 40 }}%
                  </strong>
                  of compute usage.
                </template>
                <template v-slot:value>
                  <StatusPill :enabled="isBaseTargetAutoIncreaseEnabled">
                    <span v-if="isBaseTargetAutoIncreaseEnabled">Enabled</span>
                    <span v-else>Disabled</span>
                  </StatusPill>
                </template>
              </SettingRow>
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <PanelSection header="Smart Global" rounded new-header class="settingsConstrainedWidth">
              <template v-slot:utility>
                <LastUpdated :value="settings.smart_last_updated" />
              </template>
              <SettingRow class="settingRow">
                <template v-slot:label> Reserved Instance Preference </template>
                <template v-slot:description>
                  Upfront prepayment preference for newly deployed Reserved Instances.
                </template>
                <template v-slot:value>
                  <span v-if="settings.reserved_instance_preference" class="font-weight-bold">
                    {{ settings.reserved_instance_preference }}
                  </span>
                  <span v-else class="font-weight-bold"> Monthly </span>
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Compute Shrink Allowance Target </template>
                <template v-slot:description>
                  Desired amount of compute usage decline our service should absorb without commitment becoming
                  unutilized.
                </template>
                <template v-slot:value>
                  <span
                    v-if="
                      !isNaN(+Number(settings.compute_shrink_allowance_target)) &&
                      settings.compute_shrink_allowance_target != '0'
                    "
                    class="font-weight-bold"
                  >
                    {{ settings.compute_shrink_allowance_target }}%
                  </span>
                  <span v-else> - </span>
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Reserved Instance One Year Allocation Target </template>
                <template v-slot:description>
                  Percent of allocation to cover 1-year Reserved Instances. When configured, 1-year and 3-year targets
                  will always add up to 100%.
                </template>
                <template v-slot:value>
                  <span
                    v-if="!isNaN(+Number(settings.reserved_instance_one_year_allocation_target))"
                    class="font-weight-bold"
                  >
                    {{ settings.reserved_instance_one_year_allocation_target }}%
                  </span>
                  <span v-else> - </span>
                </template>
              </SettingRow>
              <SettingRow class="settingRow">
                <template v-slot:label> Reserved Instance Three Year Allocation Target </template>
                <template v-slot:description>
                  Percent of allocation to cover 3-year Reserved Instances. When configured, 1-year and 3-year targets
                  will always add up to 100%.
                </template>
                <template v-slot:value>
                  <span
                    v-if="!isNaN(+Number(settings.reserved_instance_three_year_allocation_target))"
                    class="font-weight-bold"
                  >
                    {{ settings.reserved_instance_three_year_allocation_target }}%
                  </span>
                  <span v-else> - </span>
                </template>
              </SettingRow>
            </PanelSection>
          </div>
        </div>
        <div class="row sectional">
          <div class="col"></div>
        </div>
        <RequiresRole atleast="Editor">
          <div class="row pt-3">
            <div class="col">
              <div class="updateSettingsConstrainedWidth">
                <p>
                  <strong>Want to update your settings?</strong> We personally review setting adjustments with you to
                  ensure you are safely saving the most possible. Please click the button below to schedule a time to
                  discuss. For urgent questions or updates, please chat with us.
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="updateSettingsConstrainedWidth updateButton">
                <CalendlyButton routing-form-id="cpmm-d98-7t3">
                  <span>Update Settings</span>
                </CalendlyButton>
              </div>
            </div>
          </div>
        </RequiresRole>
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.settingsContainer {
  max-width: 950px;
}

.settingsConstrainedWidth {
  :deep(section) {
    padding: 0.5rem 1.5rem 2rem;
  }
}

.updateSettingsConstrainedWidth {
  max-width: 850px;
}

.updateButton {
  min-height: 500px;
}

.calendly {
  margin-top: -32px;
}

.subheader {
  position: relative;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 500;
}

.subheader::after {
  display: block;
  height: 3px;
  margin-top: 4px;
  content: '';
  background-color: #5c54ff;
  border-radius: 2px;
}

.description {
  font-size: 0.85rem;
}

.regionSettingRow {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.regionSettingRow:first-child {
  padding-top: 0.75rem;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
}

.tableWrapper {
  margin-top: 0.75em;
  overflow: auto;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}
</style>
