<script setup lang="ts">
import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';

import Layout from '@console/Layout.vue';

const route = useRoute();
const store = useVuexStore();

const success = ref<boolean | null>(null);

const isLoading = computed<boolean>(() => {
  return success.value == null;
});

useHead({
  title: 'Google Cloud Marketplace',
});

onMounted(async () => {
  try {
    const response = await customerService.resolveGoogleCloudMarketplaceToken(route.query.token);
    const successfulResolve = response?.data.success ?? false;

    if (successfulResolve) {
      await store.dispatch('customer/refresh');
    }

    success.value = successfulResolve;
  } catch {
    success.value = false;
  }
});
</script>

<template>
  <Layout :loading="isLoading" wide :with-announcement-banner="false">
    <template #default>
      <div class="announcement pt-4">
        <template v-if="success">
          <div class="pt-2 pb-2 text-center">
            <h1 class="display-4">Thanks!</h1>
          </div>
          <p class="lead text-center mb-0 w-50">
            We have successfully processed your Google Cloud Marketplace subscription details. You can now
            <router-link :to="{ name: 'root' }">continue the onboarding process</router-link>.
          </p>
        </template>
        <template v-else>
          <p class="lead text-center mb-0 w-50">
            We encountered an error processing your Google Cloud Marketplace subscription. We'll be in touch shortly to
            discuss next steps.
          </p>
        </template>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
.announcement {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
}
</style>
