<script lang="ts" setup>
import { ref } from 'vue';

import Modal from '@shared/design/modals/Modal.vue';

defineProps<{
  title: string;
}>();

const showModal = ref(false);
</script>

<template>
  <span class="wrapper text-primary iconBtn">
    <div class="icon" @click="showModal = true">
      <BaseIcon name="info-circle" />
    </div>
    <Modal v-model="showModal" size="lg" :title="title">
      <slot />
    </Modal>
  </span>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
}
</style>
