<script lang="ts" setup>
import type { Timeframe } from '@shared/design/TimeframeDropdown.vue';

import { useHead } from '@unhead/vue';
import { computed, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useVuexStore } from '@console/state/vuex/store';
import { dateFormat, dateUtc } from '@shared/utilities/filters';

import BuildingState from '@console/components/BuildingState.vue';
import TimeframeSelector from '@console/components/savings/TimeframeSelector.vue';
import Layout from '@console/Layout.vue';
import EffectiveSavingsRate from '@gcp/components/savings/EffectiveSavingsRate.vue';
import FlexibleCommittedUseDiscountUtilization from '@gcp/components/savings/FlexibleCommittedUseDiscountUtilization.vue';
import PortfolioActions from '@gcp/components/savings/PortfolioActions.vue';
import SavingsBreakdown from '@gcp/components/savings/SavingsBreakdown.vue';
import SavingsCallout from '@gcp/components/savings/SavingsCallout.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import PageHeader from '@shared/design/PageHeader.vue';

const store = useVuexStore();
const route = useRoute();
const router = useRouter();

const loading = ref(true);
const selectedTimeframe = ref<Timeframe>();

const context = computed(() => store.state.nav.context);
const selectedCompany = computed(() => store.getters['customer/selectedCompany']);
const isDemo = computed(() => store.getters['customer/isDemo']);
const billingAccount = computed(() => store.getters['gcp/getBillingAccountById'](context.value.id));
const savingsTimeframes = computed<Timeframe[]>(() => store.getters['gcp/cloudSqlSavingsTimeframes']);
const savingsDashboard = computed(() => store.state.gcp.cloudSqlSavingsDashboard);

const hasFlexibleCommittedUseDiscountUtilization = computed(() => {
  return (
    savingsDashboard.value.dashboard.key_metrics?.flexible_committed_use_discount_utilization !== null &&
    savingsDashboard.value.dashboard.key_metrics?.flexible_committed_use_discount_utilization !== undefined
  );
});

const hasPortfolioActions = computed(() => {
  return savingsDashboard.value.dashboard.key_metrics?.action_count > 0;
});

const loadCloudSQLSavingsDashboard = (payload: object) => store.dispatch('gcp/loadCloudSQLSavingsDashboard', payload);
const loadAllBillingAccounts = () => store.dispatch('gcp/loadAllBillingAccounts');

const load = async (timeframeKey: string | string[]) => {
  loading.value = true;

  await loadCloudSQLSavingsDashboard({
    billingAccountId: context.value.id,
    timeframe: timeframeKey,
  });

  selectedTimeframe.value = timeframeKey
    ? (savingsTimeframes.value.find(t => t.key === timeframeKey) as Timeframe)
    : savingsTimeframes.value.slice(0, 1)[0];

  await loadAllBillingAccounts();

  loading.value = false;
};

const formatDate = (date: Date, format: string = 'MMMM D, YYYY') => {
  return dateFormat(dateUtc(date), format);
};

const onChange = async (timeframe: Timeframe) => {
  await router.push({
    name: 'google_cloud_cloud_sql_savings',
    params: { timeframe: timeframe.key },
    query: route.query,
  });
};

useHead({
  title: 'Google Cloud Cloud SQL Savings',
});

onMounted(async () => {
  await load(route.params.timeframe);
});
</script>

<template>
  <Layout :loading="loading">
    <template #default>
      <BuildingState v-if="!savingsDashboard" />
      <div v-else-if="savingsDashboard && selectedTimeframe">
        <PageHeader wrap-utility>
          <h1>Cloud SQL Savings</h1>
          <template v-slot:utility>
            <TimeframeSelector
              :selected="selectedTimeframe"
              :timeframes="savingsTimeframes"
              :finalized="savingsDashboard.dashboard.is_finalized"
              :data-through-date="savingsDashboard.dashboard.data_through_date"
              @change="onChange"
            />
          </template>
        </PageHeader>
        <div v-if="savingsDashboard.subscription_start_date" class="row sectional">
          <div class="col">
            <BoxMessage type="info">
              <strong
                >{{ formatDate(savingsDashboard.subscription_start_date) }} was your first full day with the ProsperOps
                service enabled.</strong
              >
              This month is considered a transition month as the savings results reflect a blend of before and after
              ProsperOps management.
            </BoxMessage>
          </div>
        </div>

        <div v-if="savingsDashboard.dashboard.is_effective_savings_rate_adjusted" class="row sectional">
          <div class="col">
            <BoxMessage type="warning">
              <div>
                <strong
                  >The Effective Savings Rate calculation is adjusted to account for certain credits (such as promotions
                  or subscription benefits).</strong
                >
                These credits fall outside the scope of rate optimization and will remain in effect until they expire or
                are otherwise exhausted. Please
                <router-link :to="{ name: 'help' }">contact us</router-link>
                for further guidance.
              </div>
            </BoxMessage>
          </div>
        </div>
        <div class="row sectional">
          <div class="col">
            <SavingsCallout
              :month="savingsDashboard.dashboard.data_through_date"
              :summary="savingsDashboard.dashboard.savings_summary"
              :is-finalized="savingsDashboard.dashboard.is_finalized"
            />
          </div>
        </div>
        <div class="row sectional">
          <div class="col-xl-auto effectiveSavingsRate">
            <EffectiveSavingsRate
              :current="savingsDashboard.dashboard.effective_savings_rate.current"
              :previous="savingsDashboard.dashboard.effective_savings_rate.previous"
            />
          </div>
          <div class="col">
            <SavingsBreakdown
              v-if="savingsDashboard.dashboard.savings_breakdown_periods?.length > 0 && billingAccount"
              :billing-account-id="billingAccount.billing_account_id"
              :savings="savingsDashboard"
              :company="selectedCompany"
              :demo="isDemo"
              service="cloud_sql"
            />
          </div>
        </div>
        <div class="row sectional">
          <div v-if="hasPortfolioActions" class="col-lg">
            <PortfolioActions :count="savingsDashboard.dashboard.key_metrics.action_count" />
          </div>
          <div v-if="hasFlexibleCommittedUseDiscountUtilization" class="col-lg">
            <FlexibleCommittedUseDiscountUtilization
              :utilization="savingsDashboard.dashboard.key_metrics.flexible_committed_use_discount_utilization"
            />
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.effectiveSavingsRate {
  flex-basis: auto;

  @include media-breakpoint-up(xl) {
    flex-basis: 380px;
  }
}
</style>
