<script setup lang="ts">
import PanelAccent from '@shared/design/panels/PanelAccent.vue';
import TextTooltip from '@shared/design/TextTooltip.vue';

export type MetricPanelProps = {
  name: string;
  description?: string;
  icon?: object;
  color: string;
};

const props = defineProps<MetricPanelProps>();
</script>

<template>
  <PanelAccent :variant="props.color">
    <div class="d-flex align-items-center w-100">
      <div v-if="props.icon" :class="['mr-3', `text-${props.color}`]">
        <Component :is="props.icon" />
      </div>
      <div class="overflow-hidden">
        <div class="d-flex" :class="['metricValue', `text-${props.color}`]">
          <slot />
        </div>
        <div class="metricName text-muted">
          <TextTooltip placement="bottom">
            {{ props.name }}
            <template v-if="props.description" #tooltip>{{ props.description }}</template>
          </TextTooltip>
        </div>
      </div>
    </div>
  </PanelAccent>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.metricValue {
  font-size: 2.5rem;
  line-height: 1.2;
}

.metricName {
  font-size: 0.9rem;
}
</style>
