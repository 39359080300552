<script lang="ts" setup>
import type { AccountValidationError, AwsAccount, AwsAdmNonComputeOfferingCode } from '@console/services/api.models';
import type { AwsAccountId } from '@shared/services/namedIds';
import type { AxiosError } from 'axios';
import type { WatchStopHandle } from 'vue';

import * as Sentry from '@sentry/vue';
import { computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import AccountHelpers from './account_helpers';
import customerService from '@console/services/customerService';
import { useVuexStore } from '@console/state/vuex/store';
import { useFeatureStore } from '@shared/state/feature.store';
import AwsServiceHelpers from '@shared/utilities/aws_service_helpers';

import AwsAccountSummary from './AwsAccountSummary.vue';
import AwsAccountList from '@aws/components/accounts/AwsAccountList.vue';
import Pagination from '@shared/design/Pagination.vue';
import TextInput from '@shared/design/TextInput.vue';

// Keep these in the same order as the navigation
const nonComputeServices: Array<AwsAdmNonComputeOfferingCode> = [
  'rds',
  'elasti_cache',
  'memory_db',
  'open_search',
  'redshift',
];

const props = defineProps<{
  awsAccounts: AwsAccount[];
  paginate?: boolean;
}>();

const featureStore = useFeatureStore();
const vuexStore = useVuexStore();
const selectedOrganizationSettings = computed(() => vuexStore.getters['aws/selectedOrganizationSettings']);

const route = useRoute();

const filter = ref((route.query.q as string) || null);
const currentPageIndex = ref(1);
const itemsPerPage = ref(10);
const isValidatingByAwsAccount = reactive<Record<string, boolean>>({});
const validationErrorByAwsAccount = reactive<Record<string, AccountValidationError>>({});

// All used for batch validation
const batchValidationEnabled = ref(false);
const batchCountLeft = ref(0);
const currentActiveValidations = ref(0);
const batchFailureCount = ref(0);
const batchValidateWatchers = reactive<WatchStopHandle[]>([]);

const enrichedAwsAccounts = computed(() => {
  // Determine which non-compute services have multiple RI action accounts so that a tooltip can be shown
  // indicating which regions the RI action account is used for.
  const nonComputeServicesWithMultipleRiActionAccounts = nonComputeServices.filter(s => {
    const regionActionAccounts = props.awsAccounts.filter(a => a[`${s}_action_account_regions`]?.length > 0);
    return regionActionAccounts.length > 1;
  });

  return props.awsAccounts.map(account => ({
    ...account,
    status: featureStore.featureFlags.perOfferingAccountStatus
      ? AccountHelpers.getWorstStatusForAccount(account)
      : account.status,
    actionNonComputeServices: nonComputeServices
      // Only include services services with the Primary Function Toggle (PFT) enabled (if an action account was
      // setup by Ops, but the PFT isn't enabled, don't show the RI Action Account pill)
      .filter(service => selectedOrganizationSettings.value?.[`${service}_primary_function_manage_commitment`])
      .map(service => ({
        name: service,
        label: AwsServiceHelpers.getDisplayName(service),
        regions: account?.[`${service}_action_account_regions`] ?? [],
        showToolTip: nonComputeServicesWithMultipleRiActionAccounts.includes(service),
      }))
      .filter(s => s.regions.length > 0),
    hasArmEc2Scheduler:
      featureStore.armScheduler &&
      account.status_by_offering.AutonomousResourceManagementSchedulerForAwsEc2 === 'Active',
  }));
});

const hasManyAccountsThatRequireValidation = computed(
  () => enrichedAwsAccounts.value.filter(account => AwsServiceHelpers.needsSetup(account.status)).length > 1
);

const filtered = computed(() => {
  if (!filter.value) return enrichedAwsAccounts.value;

  const lowerCaseFilter = filter.value.toLowerCase();
  return enrichedAwsAccounts.value.filter(a => searchString(a).includes(lowerCaseFilter));
});

const showPagination = computed(() => {
  return props.paginate && filtered.value.length > itemsPerPage.value;
});

const currentPage = computed(() => {
  if (!showPagination.value) {
    return filtered.value;
  }
  const totalCount = enrichedAwsAccounts.value.length;
  const pageStart = (currentPageIndex.value - 1) * itemsPerPage.value;
  const pageEnd = Math.min(pageStart + itemsPerPage.value, totalCount);
  return filtered.value.slice(pageStart, pageEnd);
});

const containsMultipleRiActionAccounts = computed(() => {
  const regionActionAccounts = enrichedAwsAccounts.value.filter(a => a.action_account_regions?.length > 0);
  return regionActionAccounts.length > 1;
});

function searchString(awsAccount: AwsAccount) {
  const parts = [awsAccount.friendly_name, awsAccount.aws_account_number, awsAccount.id, awsAccount.status];
  return parts.join('|').toLowerCase();
}

async function handleBatchValidatePending() {
  const filteredPendingAccounts = enrichedAwsAccounts.value.filter(a => AwsServiceHelpers.needsSetup(a.status));
  const originalCount = filteredPendingAccounts.length;
  batchCountLeft.value = filteredPendingAccounts.length;
  const concurrency = 10;
  batchValidationEnabled.value = true;
  currentActiveValidations.value = 0;
  batchFailureCount.value = 0;

  const intervalId = setInterval(() => {
    // If we have room for more validations, start another one.
    while (currentActiveValidations.value < concurrency && filteredPendingAccounts.length > 0) {
      // We grab from the top because thats visually how they are ordered to the user.
      const account = filteredPendingAccounts.shift();

      if (account) {
        currentActiveValidations.value++;
        batchCountLeft.value--;
        onValidateAccess(account)
          .then(() => {
            const hasError = validationErrorByAwsAccount[account.id];
            if (!hasError) {
              watchAwsAccountStatus(account.id);
            } else {
              // any validation error accumulates to eventually stop processing
              batchFailureCount.value++;
            }
          })
          .catch(e => {
            // any other error prevents further processing
            batchValidationEnabled.value = false;
            Sentry.captureException(e);
          });
      }
    }

    // Once everything is completed we can stop polling.
    if (currentActiveValidations.value === 0) {
      batchValidationEnabled.value = false;
    }

    // If we have too many failures, we should stop the batch process.
    const percentFailed = batchFailureCount.value / originalCount;
    const maxFailurePercent = 0.1;
    if (percentFailed > maxFailurePercent) {
      batchValidationEnabled.value = false;
    }

    // Completion of the interval is handled by this flag. It handles the extra cleanup after everything should be completed.
    if (!batchValidationEnabled.value) {
      clearInterval(intervalId);
      batchValidateWatchers.forEach(unwatch => unwatch());
    }
  }, 100);
}

async function onValidateAccess(awsAccount: AwsAccount) {
  try {
    isValidatingByAwsAccount[awsAccount.id] = true;
    delete validationErrorByAwsAccount[awsAccount.id];
    await customerService.validateAccess(awsAccount.id);
  } catch (e) {
    const err = e as AxiosError<{ code: string; errors: object[] }>;
    const status = err?.response?.status ?? 500;
    const code = err?.response?.data?.code ?? '';
    const errors = (err?.response?.data?.errors ?? []) as AccountValidationError['errors'];
    validationErrorByAwsAccount[awsAccount.id] = { code, errors };

    // 400 errors are validation errors and should not be sent to sentry.
    if (status !== 400) {
      // Don't want to redirect to an error page when handling actions so just send it to sentry.
      Sentry.captureException(e);
    }
  } finally {
    isValidatingByAwsAccount[awsAccount.id] = false;
  }
}

function watchAwsAccountStatus(awsAccountId: AwsAccountId) {
  const unwatch = watch(enrichedAwsAccounts, updateAwsAccounts => {
    const awsAccount = updateAwsAccounts.find(account => account.id === awsAccountId);
    if (!awsAccount) {
      batchFailureCount.value++;
      return;
    }

    // We have reached an end state, so we can stop watching and allow others to start.
    if (['Active', 'ProvisioningError', 'AccessError'].includes(awsAccount.status)) {
      currentActiveValidations.value--;
      unwatch();
    }

    // If we see the account change to one of the Error end states we increment the failure count to
    // eventually stop the full batch process after a limit.
    if (['ProvisioningError', 'AccessError'].includes(awsAccount.status)) {
      batchFailureCount.value++;
    }
  });
  batchValidateWatchers.push(unwatch);
}

function handleStopBatchValidation() {
  batchValidationEnabled.value = false;
}
</script>

<template>
  <div>
    <AwsAccountSummary
      v-if="featureStore.featureFlags.perOfferingAccountStatus && awsAccounts.length > 0"
      :aws-accounts="enrichedAwsAccounts"
    />

    <TextInput id="filter" v-model="filter" type="search" class="w-100 mb-3" placeholder="Search AWS Accounts" />
    <div v-if="filtered.length > 0">
      <div class="d-flex flex-row align-items-center justify-content-end" style="height: 50px">
        <BaseButton
          v-if="!batchValidationEnabled && hasManyAccountsThatRequireValidation"
          variant="primary"
          size="sm"
          class="mr-3 mb-3 rounded-sm"
          @click="handleBatchValidatePending"
        >
          Validate All Pending
        </BaseButton>

        <div v-if="batchValidationEnabled" class="mr-2">
          <BaseButton variant="danger" size="sm" class="rounded-sm" @click="handleStopBatchValidation">
            Stop Validation
          </BaseButton>
        </div>
        <div v-if="batchValidationEnabled">{{ batchCountLeft }} left to complete.</div>
      </div>
      <AwsAccountList
        :aws-accounts="currentPage"
        :show-ri-action-account-region-tooltip="containsMultipleRiActionAccounts"
        :batch-validation-enabled="batchValidationEnabled"
        :is-validating-by-aws-account="isValidatingByAwsAccount"
        :validation-error-by-aws-account="validationErrorByAwsAccount"
        @on-validate-access="onValidateAccess"
      />
    </div>
    <div v-else class="emptyState">
      <p class="text-center mb-0">No AWS Accounts matching "{{ filter }}"</p>
    </div>
    <Pagination v-model="currentPageIndex" :total-rows="filtered.length" :per-page="itemsPerPage" class="mt-3" />
  </div>
</template>

<style scoped>
.emptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  margin-top: 8px;
  font-size: 1.2rem;
  background-color: #fff;
  box-shadow: 0 4px 3px -3px rgba(0, 0, 0, 0.3);
}
</style>
