<script lang="ts" setup>
import { computed } from 'vue';

const currentPage = defineModel<number>({ required: true });
const props = defineProps<{
  totalRows: number;
  perPage: number;
}>();

const totalPages = computed(() => Math.ceil(props.totalRows / props.perPage));

const maxVisiblePages = 5; // This should be an odd number
const visiblePages = computed(() => {
  // If there are the same or fewer pages than the max, show all pages
  if (totalPages.value <= maxVisiblePages) {
    return getPages(1, totalPages.value);
  }

  const midpoint = Math.ceil(maxVisiblePages / 2);

  // If the current page is near the start, show the first pages followed by a "..." spacer, e.g. [1, 2, 3, 4] & "..." spacer
  if (currentPage.value <= midpoint) {
    return getPages(1, maxVisiblePages - 1);
  }

  // If the current page is near the end, show the last pages preceded by a "..." spacer, e.g. "..." spacer & [7, 8, 9, 10]
  if (currentPage.value > totalPages.value - midpoint) {
    const pagesToShow = maxVisiblePages - 1;
    return getPages(totalPages.value - pagesToShow + 1, pagesToShow);
  }

  // Otherwise, show the pages around the current page with leading and trailing spacers, e.g. "..." spacer & [4, 5, 6] & "..." spacer
  const pagesBeforeCurrentToShow = midpoint - 2; // -1 for the spacer, -1 for the current page
  return getPages(currentPage.value - pagesBeforeCurrentToShow, maxVisiblePages - 2);
});

function getPages(start: number, count: number) {
  return Array.from({ length: count }, (_, i) => start + i);
}

const hasPrevious = computed(() => currentPage.value > 1);
const hasPreviousSpacer = computed(() => visiblePages.value[0] > 1);

const hasNext = computed(() => currentPage.value < totalPages.value);
const hasNextSpacer = computed(() => (visiblePages.value.at(-1) ?? 0) < totalPages.value);
</script>

<script lang="ts">
export default {
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <template v-if="totalPages > 1">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: !hasPrevious }" data-test-id="first-page">
        <button class="page-link" :disabled="!hasPrevious" @click="currentPage = 1">«</button>
      </li>
      <li class="page-item" :class="{ disabled: !hasPrevious }" data-test-id="previous-page">
        <button class="page-link" :disabled="!hasPrevious" @click="currentPage--">‹</button>
      </li>
      <li v-if="hasPreviousSpacer" class="page-item disabled" data-test-id="previous-spacer">
        <button class="page-link" disabled>…</button>
      </li>

      <li
        v-for="page in visiblePages"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
        :data-test-id="`page-${page}`"
      >
        <button class="page-link" @click="currentPage = page">{{ page }}</button>
      </li>

      <li v-if="hasNextSpacer" class="page-item disabled" data-test-id="next-spacer">
        <button class="page-link" disabled>…</button>
      </li>
      <li class="page-item" :class="{ disabled: !hasNext }" data-test-id="next-page">
        <button class="page-link" :disabled="!hasNext" @click="currentPage++">›</button>
      </li>
      <li class="page-item" :class="{ disabled: !hasNext }" data-test-id="last-page">
        <button class="page-link" :disabled="!hasNext" @click="currentPage = totalPages">»</button>
      </li>
    </ul>
  </template>
</template>

<style lang="scss" scoped>
.pagination {
  // These class names come from bootstrap
  /* stylelint-disable selector-class-pattern */
  .page-item:first-child,
  .page-item:last-child {
    .page-link {
      border-radius: 0 !important;
    }
  }
  /* stylelint-enable selector-class-pattern */
}
</style>
