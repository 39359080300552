<script lang="ts" setup>
import { computed } from 'vue';

import Dropdown from '@shared/design/dropdowns/Dropdown.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

export type SavingsShareType = 'include' | 'exclude';

const model = defineModel<SavingsShareType>({ required: true });
const props = defineProps<{
  disabled?: boolean;
}>();

interface SavingsShareOption {
  value: SavingsShareType;
  label: string;
}
const options: SavingsShareOption[] = [
  { value: 'include', label: 'Include' },
  { value: 'exclude', label: 'Exclude' },
];

const selectedLabel = computed(() => options.find(o => o.value === model.value)?.label);
</script>

<script lang="ts">
export default {
  inheritAttrs: false, // Prevent attributes from assignment to root
  compatConfig: {
    // Needed to allow use of v-model instead of v-model:model-value in compatibility mode
    // See: https://v3-migration.vuejs.org/breaking-changes/v-model
    MODE: 2,
    COMPONENT_V_MODEL: false,
  },
};
</script>

<template>
  <Dropdown
    variant="transparent"
    toggle-class="rounded-0"
    dropdown-class="rounded-0"
    dropdown-placement="bottom-start"
    :disabled="props.disabled"
  >
    <template #toggleContent>
      {{ selectedLabel }}
    </template>
    <DropdownItem v-for="o in options" :key="o.value" @click="model = o.value">
      {{ o.label }}
    </DropdownItem>
  </Dropdown>
</template>
