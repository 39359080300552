<script>
import { mapActions } from 'vuex';

import LoadingPage from '@shared/components/LoadingPage.vue';

export default {
  components: {
    LoadingPage,
  },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    awsOrganizationId: {
      type: String,
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    cloud: {
      type: String,
      required: false,
      default: null,
    },
    demo: {
      type: Boolean,
      required: false,
      default: false,
    },
    service: {
      type: String,
      required: false,
      default: 'compute',
    },
  },
  async mounted() {
    try {
      const payload = { customerId: this.customerId, demo: this.demo };
      await this.federate(payload);

      // Ensure previous account context from state is removed
      await this.reset();

      // This is here to maintain compatbility with
      // old style links that may still be in the wild using only
      // awsOrganizationId
      let contextCloud = this.cloud;
      let contextId = this.id;
      if (this.awsOrganizationId) {
        contextCloud = 'aws';
        contextId = this.awsOrganizationId;
      }
      const service = this.service;
      this.$router.replace({ name: 'root', query: { contextCloud, contextId, service } });
    } catch (e) {
      this.$router.replace({ name: 'error' });
    }
  },
  methods: {
    ...mapActions('nav', ['reset']),
    ...mapActions('customer', ['federate']),
  },
};
</script>

<template>
  <LoadingPage />
</template>
