<script setup lang="ts" generic="TValue extends string">
import type { OptionsArray } from '@console/components/filters/types';

import { computed } from 'vue';

import useSearch from '@console/components/filters/useSearch';

import FilterContainer from '@console/components/filters/FilterContainer.vue';
import DropdownDivider from '@shared/design/dropdowns/DropdownDivider.vue';
import DropdownGroup from '@shared/design/dropdowns/DropdownGroup.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

const props = defineProps<{
  modelValue?: TValue | null;
  filterValues: OptionsArray<TValue>;
  allKeysLabel?: string | null;
  label: string;
  searchPlaceHolder: string;
}>();

const emit = defineEmits(['update:modelValue']);

const allLabel = computed(() => {
  return props.allKeysLabel ?? 'All';
});

const value = computed(() => {
  return props.modelValue ?? allLabel.value;
});

const onClick = (value?: string) => {
  emit('update:modelValue', value);
};

const options = computed(() => {
  return [...props.filterValues].sort();
});

const { filteredOptions, searchRef, searchText, reset: resetSearch } = useSearch(options);
</script>

<template>
  <FilterContainer :label="label" :value="value" @shown="resetSearch">
    <div class="filterSearch">
      <input ref="searchRef" v-model="searchText" type="text" class="form-control" :placeholder="searchPlaceHolder" />
      <BaseIcon name="search" />
    </div>
    <DropdownItem v-if="!searchText" @click="onClick()">{{ allLabel }}</DropdownItem>
    <DropdownDivider v-if="!searchText" />
    <template v-for="option in filteredOptions" :key="option">
      <!-- if it's a string -->
      <DropdownItem v-if="typeof option === 'string'" :key="option" @click="onClick(option)">
        {{ option }}
      </DropdownItem>
      <!-- if it's a group -->
      <template v-else>
        <DropdownGroup v-if="option.values.length" :id="option.label" :header="option.label">
          <DropdownItem
            v-for="optionValue in option.values"
            :key="optionValue"
            class="grouped"
            @click="onClick(optionValue)"
          >
            {{ optionValue }}
          </DropdownItem>
        </DropdownGroup>
      </template>
    </template>
  </FilterContainer>
</template>

<style lang="scss" scoped>
.grouped {
  padding-left: 0.7rem;
}
</style>
