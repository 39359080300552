<script>
import Big from 'big.js';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';

import csv from '@console/lib/csvBuilder';
import NumberHelpers from '@shared/utilities/number_helpers';

import NormalizedVsActualToggle from './NormalizedVsActualToggle.vue';
import Currency from '@shared/components/Currency.vue';
import DownloadCsvIcon from '@shared/design/icons/DownloadCsvIcon.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import TextTooltip from '@shared/design/TextTooltip.vue';

export default {
  components: {
    NormalizedVsActualToggle,
    Currency,
    DownloadCsvIcon,
    PanelSection,
    TextTooltip,
  },
  props: {
    savings: {
      type: Object,
      required: true,
    },
    demo: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedTimeframe: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showNormalized: true,
      savingsCategories: [
        { field: 'base_savings', label: 'Base Savings' },
        { field: 'flex_savings', label: 'Flex Savings' },
        { field: 'flex_boost_savings', label: 'Flex Boost Savings' },
        { field: 'smart_savings', label: 'Smart Savings' },
        { field: 'inherited_savings', label: 'Inherited Savings' },
        { field: 'unbilled_savings', label: 'Unbilled Savings' },
      ],
    };
  },
  computed: {
    ...mapState('aws', ['awsOrganizations']),
    ...mapGetters('customer', ['selectedCompany', 'isReseller']),
    columns() {
      return [
        {
          key: 'effective_savings_rate',
          label: 'Effective Savings Rate',
          show: true,
        },
        {
          key: 'compute_usage',
          label: this.computeUsageLabel(),
          show: true,
        },
        {
          key: 'gross_savings',
          label: 'Gross Savings',
          show: true,
        },
        {
          key: 'net_savings',
          label: 'Net Savings',
          show: !this.demo,
        },
        ...this.lifetimeSavingsColumn(),
        ...this.incrementalLifetimeSavingsColumn(),
      ];
    },
    organizationSummaries() {
      return _.orderBy(
        this.savings.dashboard.organization_summary.details,
        ['compute_usage', 'aws_organization_friendly_name'],
        ['desc', 'asc']
      );
    },
  },
  methods: {
    computeUsageLabel() {
      if (this.isReseller) {
        return 'Arbitrageable Compute Usage';
      }
      return 'Compute Usage';
    },
    lifetimeSavingsColumn() {
      if (this.hasLifetimeSavings()) {
        return [
          {
            key: 'lifetime_savings',
            label: 'Lifetime Savings',
            show: this.hasLifetimeSavings(),
          },
        ];
      }
      return [];
    },
    hasLifetimeSavings() {
      return _.every(this.savings.dashboard.organization_summary.details, org => {
        return !!org.lifetime_savings || org.lifetime_savings === 0;
      });
    },
    incrementalLifetimeSavingsColumn() {
      if (this.hasIncrementalLifetimeSavings()) {
        return [
          {
            key: 'incremental_lifetime_savings',
            label: 'Incremental Lifetime Savings',
            show: this.hasIncrementalLifetimeSavings(),
          },
        ];
      }
      return [];
    },
    hasIncrementalLifetimeSavings() {
      return _.every(this.savings.dashboard.organization_summary.details, org => {
        return !!org.incremental_lifetime_savings || org.incremental_lifetime_savings === 0;
      });
    },
    showDelta(delta) {
      return this.showNormalized && (!!delta || delta === 0);
    },
    formatDelta(delta) {
      const value = NumberHelpers.formatDollars(Math.abs(delta));
      return delta < 0 ? `-${value}` : value;
    },
    formatSingleDecimal(delta) {
      return NumberHelpers.formatNumber(delta, 1);
    },
    deltaClasses(delta) {
      if (delta > 0) {
        return 'positive';
      }
      if (delta < 0) {
        return 'negative';
      }
      return null;
    },
    deltaIcon(delta) {
      if (delta > 0) return 'arrow-up';
      if (delta < 0) return 'arrow-down';
      return null;
    },
    tdIsPercentageAmount(columnKey) {
      return columnKey === 'effective_savings_rate';
    },
    tdPercentage(percentage) {
      return NumberHelpers.formatNumber(percentage, 1);
    },
    tdHasTooltip(columnKey, organizationSummary) {
      if (columnKey !== 'gross_savings') return false;

      return this.savingsCategories.some(c => this.isNumber(c.field, organizationSummary));
    },
    tdHasIncrementalSavingsTooltip(columnKey, organizationSummary) {
      if (columnKey !== 'net_savings') return false;

      return this.isNumber('incremental_savings', organizationSummary);
    },
    tdEmptyState(columnKey) {
      if (this.savings.dashboard.is_finalized) {
        return '-';
      }
      return columnKey === 'net_savings' ? '(not finalized)' : '-';
    },
    getValue(columnKey, organizationSummary) {
      const nonNormalizedFields = ['effective_savings_rate', 'lifetime_savings', 'incremental_lifetime_savings'];
      if (!nonNormalizedFields.includes(columnKey) && this.showNormalized) {
        columnKey += '_normalized';
      }
      return organizationSummary[columnKey];
    },
    isNumber(field, organizationSummary) {
      return _.isNumber(this.getValue(field, organizationSummary));
    },
    downloadCSV() {
      const filename = csv.organizationSummaryFileName(this.selectedTimeframe, this.selectedCompany);
      const rows = csv.organizationSummaryRows(
        this.isReseller,
        this.savings.dashboard.variant,
        this.showNormalized,
        this.demo,
        this.savings.dashboard.organization_summary.details
      );
      csv.saveFile(filename, rows);
    },
    savingsPercentage(ogranizationSummary, numeratorField, denominatorField) {
      const numerator = this.getValue(numeratorField, ogranizationSummary);
      const denominator = this.getValue(denominatorField, ogranizationSummary);

      if (denominator <= 0) {
        return null;
      }

      const percent = NumberHelpers.formatPercent(Number(Big(numerator).div(denominator)), 1);
      return `(${percent})`;
    },
    organizationId(managementAccountNumberKey, managementAwsAccountNumber) {
      return _.find(this.awsOrganizations, org => _.get(org, managementAccountNumberKey) === managementAwsAccountNumber)
        .id;
    },
    navigateToOrganization(organizationSummary) {
      const managementAccountNumberKey = this.demo
        ? 'original_management_aws_account_number'
        : 'management_aws_account_number';
      const rootPath = this.$router.resolve({
        name: 'root',
        query: {
          awsOrganizationId: this.organizationId(
            managementAccountNumberKey,
            _.get(organizationSummary, managementAccountNumberKey)
          ),
        },
      });
      window.open(rootPath.href, '_blank');
    },
  },
};
</script>

<template>
  <PanelSection header="Organization Summary" class="organizationSummary" header-item-spacing="2rem">
    <template v-slot:utility>
      <!-- Make the CSV icon and normalized vs. actual toggle children of the utility so that they can wrap
           independently. Use "header-item-spacing" to define the gap between the icon and the toggle button so that
           there isn't trailing/leading whitespace when the toggle is wrapped -->
      <BaseButton
        title="Download in CSV format"
        variant="transparent"
        class="csvIcon btn-no-focus-box-shadow"
        @click="downloadCSV"
      >
        <DownloadCsvIcon />
      </BaseButton>
      <NormalizedVsActualToggle :show-normalized="showNormalized" @change="e => (showNormalized = e)" />
    </template>

    <table class="table orgSummary">
      <thead>
        <tr class="tableHeader">
          <th></th>
          <th v-for="column in columns" :key="column.label" class="text-uppercase text-muted font-weight-normal">
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody class="tableBody">
        <tr v-for="organizationSummary in organizationSummaries" :key="organizationSummary.friendly_name">
          <th>
            <a href="#" @click="navigateToOrganization(organizationSummary)">
              {{ organizationSummary.aws_organization_friendly_name }}
            </a>
            <small class="d-xl-block description text-muted">
              #{{ organizationSummary.management_aws_account_number }}
            </small>
          </th>
          <td v-for="column in columns" :key="column.key">
            <div v-if="!column.show">-</div>
            <div v-else-if="!getValue(column.key, organizationSummary)">
              {{ tdEmptyState(column.key) }}
            </div>
            <div v-else-if="tdIsPercentageAmount(column.key)">{{ tdPercentage(organizationSummary[column.key]) }}%</div>
            <div v-else>
              <TextTooltip placement="bottom">
                <Currency class="trendValue" :value="getValue(column.key, organizationSummary)" />

                <template v-if="tdHasTooltip(column.key, organizationSummary)" #tooltip>
                  <template v-for="category in savingsCategories">
                    <div
                      v-if="isNumber(category.field, organizationSummary)"
                      :key="category.field"
                      class="tooltipBaseFlex"
                    >
                      <div>{{ category.label }}:</div>
                      <div>
                        <Currency :value="getValue(category.field, organizationSummary)" />
                        {{ savingsPercentage(organizationSummary, category.field, 'gross_savings') }}
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else-if="tdHasIncrementalSavingsTooltip(column.key, organizationSummary)" #tooltip>
                  Of this amount,
                  <Currency
                    :value="getValue('incremental_savings', organizationSummary)"
                    class="tooltipIncrementalSavings"
                  />
                  is the incremental savings generated by ProsperOps, above and beyond what you would have otherwise
                  saved (assuming you continued achieving your baseline Effective Savings Rate).
                </template>
              </TextTooltip>
              <div v-if="showDelta(organizationSummary[column.key + '_delta'])">
                <div class="delta" :class="deltaClasses(organizationSummary[column.key + '_delta'])">
                  <div>
                    <BaseIcon
                      v-if="organizationSummary[column.key + '_delta'] !== 0"
                      :name="deltaIcon(organizationSummary[column.key + '_delta'])"
                    />
                  </div>
                  <div>
                    <div v-if="organizationSummary[column.key + '_delta'] === 0" class="pr-1">
                      <small>
                        -
                        <br />
                        -
                      </small>
                    </div>
                    <div v-else>
                      <small>
                        {{ formatDelta(organizationSummary[column.key + '_delta']) }}
                        <br />
                        {{ formatSingleDecimal(organizationSummary[column.key + '_delta_percentage']) }}%
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

table.orgSummary {
  display: inline-block;
  min-width: 800px;
  max-width: 100%;
  height: auto;
  max-height: 370px;
  overflow-y: auto;
  font-size: 12px;

  th,
  td {
    width: auto;
    padding: 6px;
  }

  tbody {
    display: block;
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 5;
    width: 100%;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  @include media-breakpoint-up(md) {
    font-size: inherit;

    th,
    td {
      padding: 12px;
    }
  }
}

.tableHeader th {
  position: sticky;
  top: 0;
  width: 100%;
  overflow: hidden;
  color: white !important;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  background-color: #a7a3ff;
  border-top: 0;
  border-bottom: 0;
}

.tableHeader > th:first-child {
  width: 18%;
  text-align: left;
}

.tableBody > tr > th {
  width: 18%;
}

.tableBody > tr > td,
.tableBody > tr > th {
  font-weight: 400;
}

.tableBody > tr:first-child > td,
.tableBody > tr:first-child > th {
  border-top: 0;
}

.tableBody td {
  text-align: center;
}

.tableBody > td > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.delta {
  display: flex;
  justify-content: center;
}

.delta small {
  font-weight: 500;
}

.delta.positive {
  color: map-get($theme-colors, 'success');
}

.delta.negative {
  color: map-get($theme-colors, 'danger');
}

.delta > div:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
}

.tooltipBaseFlex {
  font-size: 12px;

  > div {
    display: inline-block;
  }

  > div:first-child {
    padding-right: 0.25rem;
  }

  > div > span {
    font-weight: bold;
  }
}

.tooltipIncrementalSavings {
  font-weight: bold;
}

.csvIcon {
  height: 32px;

  // These styles are needed to prevent the icon from expanding the parent container due to the vertical padding. Also,
  // without the height, the panel will have a vertical scrollbar
  padding: 0;

  > svg {
    width: 24px;
    height: 24px;
  }
}
</style>
