<script lang="ts" setup>
import { computed, ref } from 'vue';

import SolidGaugeChart from '@console/components/charts/SolidGaugeChart.vue';
import Percentage from '@shared/components/Percentage.vue';
import Modal from '@shared/design/modals/Modal.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps({
  current: {
    type: Number,
    required: true,
  },
  previous: {
    type: Number,
    required: true,
  },
});

const showModal = ref(false);
const currentEsr = computed(() => props.current * 100);
const previousEsr = computed(() => props.previous * 100);
const hasPreviousEsr = computed(() => props.previous !== undefined && props.previous !== null);
</script>

<template>
  <PanelSection header="Effective Savings Rate" class="esr">
    <Modal v-model="showModal" size="lg" title="Effective Savings Rate before ProsperOps">
      <p>Your Effective Savings Rate for the 90 days prior to ProsperOps was:</p>
      <div class="pb-4 text-center display-4">
        <strong><Percentage :value="previousEsr" :decimals="1" :convert-to-percent="false" /> </strong>
      </div>
      <p>
        Note: This value assumes there were <strong>zero</strong> management costs (e.g. cloud management platform or
        other tooling/script development, people time, etc.) necessary to achieve this savings outcome. Effective
        Savings Rate after ProsperOps includes our savings share so is inclusive of management costs.
      </p>
    </Modal>
    <div class="d-flex align-items-center">
      <SolidGaugeChart title="Savings Rate" :value="currentEsr" class="mx-auto" />
    </div>
    <p class="currentEsr mx-3">
      ProsperOps algorithms optimize for Effective Savings Rate. This metric best represents overall savings performance
      and always correlates with maximum savings.
      <a href="https://www.effectivesavingsrate.com/" class="ml-2" target="_blank">Learn More</a>
    </p>
    <p v-if="hasPreviousEsr" class="priorEsr mx-3 mt-3 pt-3 text-center">
      <a href="#" class="text-muted" @click.prevent="showModal = true">
        <small>What was my Effective Savings Rate before ProsperOps?</small>
      </a>
    </p>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import '@shared/scss/colors.scss';

.esr {
  height: max-content;
}

.priorEsr {
  border-top: 1px solid $gray-200;
}
</style>
