<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import * as mask from '@console/lib/mask';

import ConvertibleRiExpirationTimeline from './ConvertibleRiExpirationTimeline.vue';
import RegionFilter from '@aws/components/filters/RegionFilter.vue';
import FilterContainer from '@console/components/filters/FilterContainer.vue';
import DropdownDivider from '@shared/design/dropdowns/DropdownDivider.vue';
import DropdownItem from '@shared/design/dropdowns/DropdownItem.vue';

const all = () => true;
const onlyRiActionAccounts = timeline => timeline.is_ri_action_account;

export default {
  components: {
    ConvertibleRiExpirationTimeline,
    RegionFilter,
    FilterContainer,
    DropdownDivider,
    DropdownItem,
  },
  props: {
    criExpirationTimelines: {
      type: Array,
      required: true,
    },
    dataThroughDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      accountFilterSearch: '',
      accountFilter: onlyRiActionAccounts,

      selectedRegion: null,

      // Expose pre-canned filters so they can be used in Vue markup.
      all: all,
      onlyRiActionAccounts: onlyRiActionAccounts,
    };
  },
  computed: {
    ...mapGetters('customer', ['isDemo']),
    timelines() {
      // First, we sum hourly amortized commitment at each level of the data structure, from the bottom up:
      //
      // - timeline.details[*].expiration_date_details[*]
      // - timeline.details[*]
      // - timeline
      //
      // This allows us to easily sum timelines by region for sorting.
      let timelines = _.map(this.criExpirationTimelines, timeline => {
        const aggregateCommitment = _.sumBy(timeline.details, timelineDetail => {
          return _.sumBy(timelineDetail.expiration_date_details, edd => edd.amortized_hourly_commitment);
        });

        return { ...timeline, amortized_hourly_commitment: aggregateCommitment };
      });

      // Next, we sort timelines by field in reverse order by the following fields: friendly_name, is_ri_action_account
      timelines = _.chain(timelines)
        .sortBy(t => t.friendly_name)
        .sortBy(t => !t.is_ri_action_account);

      // Group timelines by region, sort by the largest commitment per region (orderBy -> reverse), and
      // flatten timelines back out into a single array. The final sort order is: aggregate regional commitment, whether
      // it is an RI action account, and friendly name.
      timelines = timelines
        .groupBy(t => t.region)
        .orderBy(timelinesForRegion => _.sumBy(timelinesForRegion, t => t.amortized_hourly_commitment))
        .reverse()
        .flatten();

      // Apply user-selected filters.
      timelines = timelines.filter(this.regionFilter);
      timelines = timelines.filter(this.accountFilter);

      return timelines.value();
    },
    regions() {
      const regions = _.chain(this.criExpirationTimelines)
        .map(t => ({ system_name: t.region, display_name: t.region_display_name }))
        .uniqBy(r => r.system_name)
        .filter(r => this.isRegionFilterEnabled(r.system_name))
        .sort((a, b) => a.display_name.localeCompare(b.display_name))
        .value();

      return regions;
    },
    accounts() {
      const accounts = _.chain(this.criExpirationTimelines)
        .map(t => ({
          aws_account_id: t.aws_account_id,
          aws_account_number: t.aws_account_number,
          friendly_name: t.friendly_name,
        }))
        .uniqBy(t => t.aws_account_id)
        .filter(t => this.isAccountFilterEnabled(t.aws_account_id))
        .sortBy(t => t.friendly_name)
        .value();

      return accounts;
    },
    filteredAccounts() {
      return _.filter(this.accounts, t => {
        if (!this.accountFilterSearch) {
          return true;
        }

        const filter = this.accountFilterSearch.toLowerCase();
        const fieldsToSearch = _.pick(t, ['friendly_name', 'aws_account_number']);
        return _.some(fieldsToSearch, value => value.toLowerCase().includes(filter));
      });
    },
    selectedAccount() {
      return _.find(this.accounts, this.accountFilter);
    },
    isRiActionAccountEnabled() {
      const matchesFilter = _.chain(this.criExpirationTimelines)
        .filter(this.regionFilter)
        .filter(onlyRiActionAccounts)
        .some()
        .value();

      return matchesFilter;
    },
  },
  methods: {
    awsAccountNameDisplayValue(awsAccount) {
      return this.isDemo ? mask.generateAwsAccountName(awsAccount.aws_account_id) : awsAccount.friendly_name;
    },
    awsAccountNumberDisplayValue(awsAccount) {
      return this.isDemo ? mask.redact(awsAccount.aws_account_number) : awsAccount.aws_account_number;
    },
    selectAccount(id) {
      this.accountFilter = timeline => timeline.aws_account_id === id;
    },
    selectRiActionAccounts() {
      this.accountFilter = onlyRiActionAccounts;
    },
    clearAccount() {
      this.accountFilter = all;
    },
    resetSearch() {
      this.accountFilterSearch = '';
      this.$refs.accountFilterSearch.focus();
    },
    isRegionFilterEnabled(region) {
      // Find all regions that match the account filter and determine
      // whether the provided `region` would yield any results.
      const matchesFilters = _.chain(this.criExpirationTimelines)
        .filter(this.accountFilter)
        .filter(t => t.region === region)
        .some()
        .value();

      return matchesFilters;
    },
    isAccountFilterEnabled(awsAccountId) {
      // Find all accounts that match the region filter and determine
      // whether the provided `awsAccountId` would yield any results.
      const matchesFilters = _.chain(this.criExpirationTimelines)
        .filter(this.regionFilter)
        .filter(t => t.aws_account_id === awsAccountId)
        .some()
        .value();

      return matchesFilters;
    },
    regionFilter(timeline) {
      return this.selectedRegion ? timeline.region === this.selectedRegion.system_name : true;
    },
  },
};
</script>

<template>
  <div class="regionalCommitments">
    <div class="regionalCommitmentsFilters row sectional">
      <div class="col d-flex flex-row flex-wrap gap-2">
        <!-- TODO: update to just v-model (remove :model-value) once we're no longer in compatibility mode.
             see: https://v3-migration.vuejs.org/breaking-changes/v-model -->
        <RegionFilter v-model:model-value="selectedRegion" :regions="regions" />
        <FilterContainer label="Accounts" type="account" @shown="resetSearch()">
          <template #selected-value>
            <span v-if="accountFilter === all">All Accounts</span>
            <span v-else-if="accountFilter === onlyRiActionAccounts">RI Action Account</span>
            <span v-else class="d-inline-flex align-items-center gap-2">
              {{ awsAccountNameDisplayValue(selectedAccount) }}
              <small>{{ awsAccountNumberDisplayValue(selectedAccount) }}</small>
            </span>
          </template>

          <div class="filterSearch">
            <input
              ref="accountFilterSearch"
              v-model="accountFilterSearch"
              type="text"
              class="form-control"
              placeholder="Search Accounts"
            />
            <BaseIcon name="search" />
          </div>
          <DropdownItem v-if="!accountFilterSearch" @click="clearAccount">All Accounts</DropdownItem>
          <DropdownItem v-if="!accountFilterSearch && isRiActionAccountEnabled" @click="selectRiActionAccounts()">
            RI Action Account
          </DropdownItem>
          <DropdownDivider v-if="!accountFilterSearch" />
          <DropdownItem
            v-for="account in filteredAccounts"
            :key="account.aws_account_id"
            @click="selectAccount(account.aws_account_id)"
          >
            <span class="d-inline-flex align-items-center gap-2">
              {{ awsAccountNameDisplayValue(account) }}
              <small class="text-muted">{{ awsAccountNumberDisplayValue(account) }}</small>
            </span>
          </DropdownItem>
        </FilterContainer>
      </div>
    </div>
    <div class="row sectional">
      <div class="col">
        <h3 class="regionalCommitmentsHeader">Convertible RI Expiration Timeline</h3>
        <div
          v-for="timeline in timelines"
          :key="`${timeline.region}:${timeline.aws_account_number}`"
          class="regionalCommitmentsPanel"
        >
          <ConvertibleRiExpirationTimeline :timeline="timeline" :data-through-date="dataThroughDate" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@shared/scss/colors.scss';

.regionalCommitmentsHeader {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.regionalCommitmentsPanel {
  margin-bottom: 2rem;
}
</style>
